import {
  Directive,
  EventEmitter,
  HostListener,
  Input,
  Output,
} from '@angular/core';
import { CxDialogService } from '@bbraun/cortex/dialog';
import { firstValueFrom, forkJoin } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Directive({
  selector: '[hpmConfirmDelete]',
})
export class ConfirmDeleteDirective {
  @Input('hpmConfirmDelete') message?: string;
  @Output() confirmation: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    private dialogService: CxDialogService,
    private translateService: TranslateService,
  ) {}

  @HostListener('click')
  async openDialog(): Promise<void> {
    const [title, messagePrefix, cancel, confirm] = await firstValueFrom(
      forkJoin([
        this.translateService.get('UTILS.DELETE_DIALOG.TITLE'),
        this.translateService.get('UTILS.DELETE_DIALOG.MESSAGE'),
        this.translateService.get('UTILS.DELETE_DIALOG.CANCEL'),
        this.translateService.get('UTILS.DELETE_DIALOG.CONFIRM'),
      ]),
    );
    const message = this.message ? `${messagePrefix} ${this.message}` : '';
    this.dialogService
      .openDeleteDialog(title, message, confirm, cancel, false)
      .subscribe((confirm) => {
        this.confirmation.emit(confirm);
      });
  }
}
