import * as i1 from '@angular/cdk/listbox';
import { CdkOption, CdkListbox, CdkListboxModule } from '@angular/cdk/listbox';
import * as i0 from '@angular/core';
import { Component, Self, HostBinding, Input, Host, NgModule } from '@angular/core';
import * as i2 from '@bbraun/cortex/list';
import { CxFlatListItemComponent, CxListItemComponent, CxListModule } from '@bbraun/cortex/list';
export * from '@bbraun/cortex/list';
import * as i2$1 from '@angular/common';
import { CommonModule } from '@angular/common';
import * as i3 from '@angular/material/checkbox';
import { MatCheckboxModule } from '@angular/material/checkbox';

/* eslint-disable @angular-eslint/no-inputs-metadata-property */
const _c0 = [[["cx-flat-list-option"]]];
const _c1 = a0 => ({
  "cx-list-title pt-m pt-xs pb-xs": a0
});
const _c2 = ["cx-flat-list-option"];
const _c3 = ["*", [["cx-list-option"]]];
const _c4 = a0 => ({
  "mt-m cx-list-title": a0
});
const _c5 = ["*", "cx-list-option"];
function CxListOptionComponent_ng_template_1_Template(rf, ctx) {
  if (rf & 1) {
    const _r12 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "mat-checkbox", 6, 7);
    i0.ɵɵlistener("click", function CxListOptionComponent_ng_template_1_Template_mat_checkbox_click_0_listener() {
      i0.ɵɵrestoreView(_r12);
      const ctx_r11 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r11.toggleSelectionManually());
    });
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵproperty("value", ctx_r0.cdkOption.value)("checked", ctx_r0.cdkOption.isSelected());
  }
}
function CxListOptionComponent_ng_template_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵprojection(0);
  }
}
function CxListOptionComponent_ng_template_5_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵprojection(0, 1);
  }
}
function CxListOptionComponent_ng_template_7_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵprojection(0, 2);
  }
}
function CxListOptionComponent_ng_template_9_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵprojection(0, 3);
  }
}
const _c6 = [[["cx-image-thumbnail"]], "*", [["cx-menu"]], [["mat-icon", 8, "cx-list-drag-handle"]]];
const _c7 = ["cx-image-thumbnail", "*", "cx-menu", "mat-icon.cx-list-drag-handle"];
class CxFlatListOptionComponent extends CxFlatListItemComponent {
  constructor(cdkOption) {
    super();
    this.cdkOption = cdkOption;
    this.role = 'listitem';
  }
  static {
    this.ɵfac = function CxFlatListOptionComponent_Factory(t) {
      return new (t || CxFlatListOptionComponent)(i0.ɵɵdirectiveInject(i1.CdkOption, 2));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: CxFlatListOptionComponent,
      selectors: [["cx-flat-list-option"]],
      hostVars: 1,
      hostBindings: function CxFlatListOptionComponent_HostBindings(rf, ctx) {
        if (rf & 2) {
          i0.ɵɵhostProperty("role", ctx.role);
        }
      },
      features: [i0.ɵɵHostDirectivesFeature([{
        directive: i1.CdkOption,
        inputs: ["cdkOption", "value"]
      }]), i0.ɵɵInheritDefinitionFeature],
      decls: 1,
      vars: 5,
      consts: [[3, "icon", "iconSvg", "text"]],
      template: function CxFlatListOptionComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelement(0, "cx-flat-list-item", 0);
        }
        if (rf & 2) {
          i0.ɵɵclassProp("cx-list-option-selected", ctx.cdkOption.isSelected());
          i0.ɵɵproperty("icon", ctx.icon)("iconSvg", ctx.iconSvg)("text", ctx.text);
        }
      },
      dependencies: [i2.CxFlatListItemComponent],
      encapsulation: 2
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CxFlatListOptionComponent, [{
    type: Component,
    args: [{
      selector: 'cx-flat-list-option',
      hostDirectives: [{
        directive: CdkOption,
        inputs: ['cdkOption: value']
      }],
      template: "<cx-flat-list-item\n  [icon]=\"icon\"\n  [iconSvg]=\"iconSvg\"\n  [text]=\"text\"\n  [class.cx-list-option-selected]=\"cdkOption.isSelected()\"\n>\n</cx-flat-list-item>\n"
    }]
  }], () => [{
    type: i1.CdkOption,
    decorators: [{
      type: Self
    }]
  }], {
    role: [{
      type: HostBinding
    }]
  });
})();

/* eslint-disable @angular-eslint/no-inputs-metadata-property */
/* eslint-disable @angular-eslint/no-output-rename */
/* eslint-disable @angular-eslint/no-outputs-metadata-property */
class CxFlatListboxComponent {
  constructor() {
    this.isClickable = true;
  }
  static {
    this.ɵfac = function CxFlatListboxComponent_Factory(t) {
      return new (t || CxFlatListboxComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: CxFlatListboxComponent,
      selectors: [["cx-flat-listbox"]],
      hostVars: 2,
      hostBindings: function CxFlatListboxComponent_HostBindings(rf, ctx) {
        if (rf & 2) {
          i0.ɵɵclassProp("cx-list-clickable", ctx.isClickable);
        }
      },
      inputs: {
        listTitle: "listTitle"
      },
      exportAs: ["cxFlatListbox"],
      features: [i0.ɵɵHostDirectivesFeature([{
        directive: i1.CdkListbox,
        inputs: ["cdkListboxValue", "value"],
        outputs: ["cdkListboxValueChange", "valueChange"]
      }])],
      ngContentSelectors: _c2,
      decls: 5,
      vars: 4,
      consts: [[1, "cx-list", "cx-flat-list"], [1, "font-s", "font-weight-bold", "text-uppercase", "mx-sm", 3, "ngClass"]],
      template: function CxFlatListboxComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef(_c0);
          i0.ɵɵelementStart(0, "div", 0)(1, "div", 1);
          i0.ɵɵtext(2);
          i0.ɵɵelementEnd();
          i0.ɵɵelementStart(3, "ul");
          i0.ɵɵprojection(4);
          i0.ɵɵelementEnd()();
        }
        if (rf & 2) {
          i0.ɵɵadvance();
          i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction1(2, _c1, ctx.listTitle));
          i0.ɵɵadvance();
          i0.ɵɵtextInterpolate1(" ", ctx.listTitle, " ");
        }
      },
      dependencies: [i2$1.NgClass],
      encapsulation: 2
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CxFlatListboxComponent, [{
    type: Component,
    args: [{
      selector: 'cx-flat-listbox',
      hostDirectives: [{
        directive: CdkListbox,
        inputs: ['cdkListboxValue: value'],
        outputs: ['cdkListboxValueChange: valueChange']
      }],
      exportAs: 'cxFlatListbox',
      template: "<div class=\"cx-list cx-flat-list\">\n  <div\n    class=\"font-s font-weight-bold text-uppercase mx-sm\"\n    [ngClass]=\"{ 'cx-list-title pt-m pt-xs pb-xs': listTitle }\"\n  >\n    {{ listTitle }}\n  </div>\n  <ul>\n    <ng-content select=\"cx-flat-list-option\"></ng-content>\n  </ul>\n</div>\n"
    }]
  }], null, {
    listTitle: [{
      type: Input
    }],
    isClickable: [{
      type: HostBinding,
      args: ['class.cx-list-clickable']
    }]
  });
})();

/* eslint-disable @angular-eslint/no-inputs-metadata-property */
/* eslint-disable @angular-eslint/no-output-rename */
/* eslint-disable @angular-eslint/no-outputs-metadata-property */
class CxListboxComponent {
  constructor(cdkListbox) {
    this.cdkListbox = cdkListbox;
  }
  get isMultiple() {
    return !this.cdkListbox.multiple;
  }
  static {
    this.ɵfac = function CxListboxComponent_Factory(t) {
      return new (t || CxListboxComponent)(i0.ɵɵdirectiveInject(i1.CdkListbox, 2));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: CxListboxComponent,
      selectors: [["cx-listbox"]],
      hostVars: 2,
      hostBindings: function CxListboxComponent_HostBindings(rf, ctx) {
        if (rf & 2) {
          i0.ɵɵclassProp("cx-list-clickable", ctx.isMultiple);
        }
      },
      inputs: {
        listTitle: "listTitle"
      },
      exportAs: ["cxListbox"],
      features: [i0.ɵɵHostDirectivesFeature([{
        directive: i1.CdkListbox,
        inputs: ["cdkListboxMultiple", "multiple", "cdkListboxValue", "value"],
        outputs: ["cdkListboxValueChange", "valueChange"]
      }])],
      ngContentSelectors: _c5,
      decls: 7,
      vars: 6,
      consts: [[1, "cx-list", "cx-regular-list", "cx-list-divided"], [1, "font-s", "font-weight-bold", "text-uppercase", "mx-sm", "d-flex", "align-items-end", "justify-content-between", 3, "ngClass"]],
      template: function CxListboxComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef(_c3);
          i0.ɵɵelementStart(0, "div", 0)(1, "div", 1)(2, "div");
          i0.ɵɵtext(3);
          i0.ɵɵelementEnd();
          i0.ɵɵprojection(4);
          i0.ɵɵelementEnd();
          i0.ɵɵelementStart(5, "ul");
          i0.ɵɵprojection(6, 1);
          i0.ɵɵelementEnd()();
        }
        if (rf & 2) {
          i0.ɵɵadvance();
          i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction1(4, _c4, ctx.listTitle));
          i0.ɵɵadvance();
          i0.ɵɵclassProp("pb-xs", ctx.listTitle);
          i0.ɵɵadvance();
          i0.ɵɵtextInterpolate(ctx.listTitle);
        }
      },
      dependencies: [i2$1.NgClass],
      encapsulation: 2
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CxListboxComponent, [{
    type: Component,
    args: [{
      selector: 'cx-listbox',
      hostDirectives: [{
        directive: CdkListbox,
        inputs: ['cdkListboxMultiple: multiple', 'cdkListboxValue: value'],
        outputs: ['cdkListboxValueChange: valueChange']
      }],
      exportAs: 'cxListbox',
      template: "<div class=\"cx-list cx-regular-list cx-list-divided\">\n  <div\n    class=\"font-s font-weight-bold text-uppercase mx-sm d-flex align-items-end justify-content-between\"\n    [ngClass]=\"{ 'mt-m cx-list-title': listTitle }\"\n  >\n    <div [class.pb-xs]=\"listTitle\">{{ listTitle }}</div>\n    <ng-content></ng-content>\n  </div>\n  <ul>\n    <ng-content select=\"cx-list-option\"></ng-content>\n  </ul>\n</div>\n"
    }]
  }], () => [{
    type: i1.CdkListbox,
    decorators: [{
      type: Self
    }]
  }], {
    listTitle: [{
      type: Input
    }],
    isMultiple: [{
      type: HostBinding,
      args: ['class.cx-list-clickable']
    }]
  });
})();

/* eslint-disable @angular-eslint/no-inputs-metadata-property */
class CxListOptionComponent extends CxListItemComponent {
  constructor(cdkOption, cdkListbox, cxListbox) {
    super();
    this.cdkOption = cdkOption;
    this.cdkListbox = cdkListbox;
    this.cxListbox = cxListbox;
    this.isDraggableMode = false;
    this.multiple = false;
    this.multiple = this.cdkListbox.multiple;
    if (this.multiple) {
      this.cdkOption.disabled = true;
    }
  }
  toggleSelectionManually() {
    if (this.multiple && this.cdkOption.isSelected()) {
      this.cdkOption.deselect();
      return;
    }
    if (this.multiple && !this.cdkOption.isSelected()) {
      this.cdkOption.select();
      return;
    }
  }
  static {
    this.ɵfac = function CxListOptionComponent_Factory(t) {
      return new (t || CxListOptionComponent)(i0.ɵɵdirectiveInject(i1.CdkOption, 2), i0.ɵɵdirectiveInject(i1.CdkListbox, 1), i0.ɵɵdirectiveInject(CxListboxComponent, 1));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: CxListOptionComponent,
      selectors: [["cx-list-option"]],
      inputs: {
        isDraggableMode: "isDraggableMode"
      },
      features: [i0.ɵɵHostDirectivesFeature([{
        directive: i1.CdkOption,
        inputs: ["cdkOption", "value"]
      }]), i0.ɵɵInheritDefinitionFeature],
      ngContentSelectors: _c7,
      decls: 11,
      vars: 20,
      consts: [[3, "subtitle", "itemTitle", "caption", "captionColor", "secondaryCaptionPosition", "primaryIcon", "primaryIconSvg", "primaryIconColor", "secondaryIcon", "secondaryIconSvg", "isDraggableMode", "contentTemplate", "thumbnailTemplate", "menuTemplate", "dragHandleTemplate", "optionTemplate"], ["optionMultipleRef", ""], ["thumbnailRef", ""], ["contentRef", ""], ["menuRef", ""], ["dragHandleRef", ""], [3, "value", "checked", "click"], ["matCheckbox", "matCheckbox"]],
      template: function CxListOptionComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef(_c6);
          i0.ɵɵelement(0, "cx-list-item", 0);
          i0.ɵɵtemplate(1, CxListOptionComponent_ng_template_1_Template, 2, 2, "ng-template", null, 1, i0.ɵɵtemplateRefExtractor)(3, CxListOptionComponent_ng_template_3_Template, 1, 0, "ng-template", null, 2, i0.ɵɵtemplateRefExtractor)(5, CxListOptionComponent_ng_template_5_Template, 1, 0, "ng-template", null, 3, i0.ɵɵtemplateRefExtractor)(7, CxListOptionComponent_ng_template_7_Template, 1, 0, "ng-template", null, 4, i0.ɵɵtemplateRefExtractor)(9, CxListOptionComponent_ng_template_9_Template, 1, 0, "ng-template", null, 5, i0.ɵɵtemplateRefExtractor);
        }
        if (rf & 2) {
          const _r1 = i0.ɵɵreference(2);
          const _r3 = i0.ɵɵreference(4);
          const _r5 = i0.ɵɵreference(6);
          const _r7 = i0.ɵɵreference(8);
          const _r9 = i0.ɵɵreference(10);
          i0.ɵɵclassProp("clickable", !ctx.multiple)("cx-list-option-selected", ctx.cdkOption.isSelected());
          i0.ɵɵproperty("subtitle", ctx.subtitle)("itemTitle", ctx.itemTitle)("caption", ctx.caption)("captionColor", ctx.captionColor)("secondaryCaptionPosition", ctx.secondaryCaptionPosition)("primaryIcon", ctx.primaryIcon)("primaryIconSvg", ctx.primaryIconSvg)("primaryIconColor", ctx.primaryIconColor)("secondaryIcon", ctx.secondaryIcon)("secondaryIconSvg", ctx.secondaryIconSvg)("isDraggableMode", ctx.isDraggableMode)("contentTemplate", _r5)("thumbnailTemplate", _r3)("menuTemplate", _r7)("dragHandleTemplate", _r9)("optionTemplate", ctx.multiple && _r1);
        }
      },
      dependencies: [i3.MatCheckbox, i2.CxListItemComponent],
      encapsulation: 2
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CxListOptionComponent, [{
    type: Component,
    args: [{
      selector: 'cx-list-option',
      hostDirectives: [{
        directive: CdkOption,
        inputs: ['cdkOption: value']
      }],
      template: "<cx-list-item\n  [class.clickable]=\"!multiple\"\n  [subtitle]=\"subtitle\"\n  [itemTitle]=\"itemTitle\"\n  [caption]=\"caption\"\n  [captionColor]=\"captionColor\"\n  [secondaryCaptionPosition]=\"secondaryCaptionPosition\"\n  [primaryIcon]=\"primaryIcon\"\n  [primaryIconSvg]=\"primaryIconSvg\"\n  [primaryIconColor]=\"primaryIconColor\"\n  [secondaryIcon]=\"secondaryIcon\"\n  [secondaryIconSvg]=\"secondaryIconSvg\"\n  [class.cx-list-option-selected]=\"cdkOption.isSelected()\"\n  [isDraggableMode]=\"isDraggableMode\"\n  [contentTemplate]=\"contentRef\"\n  [thumbnailTemplate]=\"thumbnailRef\"\n  [menuTemplate]=\"menuRef\"\n  [dragHandleTemplate]=\"dragHandleRef\"\n  [optionTemplate]=\"multiple && optionMultipleRef\"\n>\n</cx-list-item>\n<ng-template #optionMultipleRef>\n  <mat-checkbox\n    #matCheckbox=\"matCheckbox\"\n    [value]=\"cdkOption.value\"\n    [checked]=\"cdkOption.isSelected()\"\n    (click)=\"toggleSelectionManually()\"\n  ></mat-checkbox>\n</ng-template>\n\n<ng-template #thumbnailRef>\n  <ng-content select=\"cx-image-thumbnail\"></ng-content>\n</ng-template>\n<ng-template #contentRef>\n  <ng-content></ng-content>\n</ng-template>\n<ng-template #menuRef>\n  <ng-content select=\"cx-menu\"></ng-content>\n</ng-template>\n<ng-template #dragHandleRef>\n  <ng-content select=\"mat-icon.cx-list-drag-handle\"></ng-content>\n</ng-template>\n\n"
    }]
  }], () => [{
    type: i1.CdkOption,
    decorators: [{
      type: Self
    }]
  }, {
    type: i1.CdkListbox,
    decorators: [{
      type: Host
    }]
  }, {
    type: CxListboxComponent,
    decorators: [{
      type: Host
    }]
  }], {
    isDraggableMode: [{
      type: Input
    }]
  });
})();
const components = [CxListOptionComponent, CxListboxComponent, CxFlatListOptionComponent, CxFlatListboxComponent];
class CxSelectableListModule {
  static {
    this.ɵfac = function CxSelectableListModule_Factory(t) {
      return new (t || CxSelectableListModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: CxSelectableListModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      imports: [CommonModule, MatCheckboxModule, CdkListboxModule, CxListModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CxSelectableListModule, [{
    type: NgModule,
    args: [{
      declarations: [...components],
      imports: [CommonModule, MatCheckboxModule, CdkListboxModule, CxListModule],
      exports: [...components]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { CxFlatListOptionComponent, CxFlatListboxComponent, CxListOptionComponent, CxListboxComponent, CxSelectableListModule };
