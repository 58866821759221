<cx-search-bar (search)="searchAll($event)"
               [matAutocomplete]="autocomplete"
               [value]="selectedValue.asObservable()"
               [placeholder]="placeholder | translate"
               class="searchBar">
  <mat-autocomplete #autocomplete
                    (optionSelected)="onAutocompleteSelected($event.option.viewValue)">
    <mat-option *ngFor="let component of filteredAutocompleteOptions">
      {{component}}
    </mat-option>
  </mat-autocomplete>
</cx-search-bar>
