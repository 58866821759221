import * as i0 from '@angular/core';
import { EventEmitter, inject, DestroyRef, Component, HostBinding, Input, Output, ViewChild, HostListener, NgModule } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import * as i5 from '@angular/forms';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import * as i4 from '@angular/material/autocomplete';
import { MatAutocompleteTrigger, MatAutocompleteModule, MAT_AUTOCOMPLETE_DEFAULT_OPTIONS } from '@angular/material/autocomplete';
import { DEBOUNCE_TIME } from '@bbraun/cortex/shared';
export * from '@bbraun/cortex/shared';
import 'rxjs';
import { debounceTime, map, distinctUntilChanged } from 'rxjs/operators';
import * as i1 from '@angular/common';
import { CommonModule } from '@angular/common';
import * as i2 from '@angular/material/button';
import { MatButtonModule } from '@angular/material/button';
import * as i3 from '@angular/material/icon';
import { MatIconModule } from '@angular/material/icon';

/* eslint-disable no-underscore-dangle */
/**
 * Search bar component with two available modes (SearchBarMode.EXPANDED` / `SearchBarMode.COLLAPSED`).
 * Entered search term will be emitted.
 */
const _c0 = ["searchBar"];
function CxSearchBarComponent_mat_icon_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "mat-icon", 6);
    i0.ɵɵtext(1, "search");
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵclassProp("text-gray-50", ctx_r0.disabled);
  }
}
function CxSearchBarComponent_button_5_Template(rf, ctx) {
  if (rf & 1) {
    const _r4 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "button", 7);
    i0.ɵɵlistener("mouseup", function CxSearchBarComponent_button_5_Template_button_mouseup_0_listener() {
      i0.ɵɵrestoreView(_r4);
      const ctx_r3 = i0.ɵɵnextContext();
      ctx_r3.clear();
      return i0.ɵɵresetView(ctx_r3.focus());
    });
    i0.ɵɵelementStart(1, "mat-icon");
    i0.ɵɵtext(2, "close");
    i0.ɵɵelementEnd()();
  }
}
const _c1 = ["*", [["mat-autocomplete"]]];
const _c2 = ["*", "mat-autocomplete"];
class CxSearchBarComponent {
  constructor() {
    /**
     * Placeholder of search input.
     */
    this.placeholder = 'Search';
    /**
     * MatAutocomplete instance. You need to initialize a mat-autocomplete between the `cx-search-bar` element
     * and pass the instance in this Input.
     */
    this.matAutocomplete = null;
    /**
     * Minimum length of the search term before triggering search
     */
    this.minLength = 1;
    /**
     * Whether search-bar should blur on every new value passed with `value` observable
     * @see {@link value} property
     */
    this.blurOnValueChange = true;
    /**
     * Whether search-bar should have regular styles of an input field
     */
    this.styleAsRegularInput = false;
    /**
     * Whether search-bar should have search icon
     */
    this.hideIcon = false;
    /**
     * Entered search term which will be emitted on change.
     */
    this.search = new EventEmitter();
    /**
     * Form to hold the search term
     */
    this.searchForm = new FormControl({
      value: '',
      disabled: this.disabled
    });
    this.destroyRef = inject(DestroyRef);
    this._disabled = false;
  }
  /**
   * Whether search bar is disabled
   */
  get disabled() {
    return this._disabled;
  }
  set disabled(value) {
    this._disabled = value;
    if (value) {
      this.clear();
      this.searchForm.disable();
    } else {
      this.searchForm.enable();
    }
  }
  // Fixes placeholder glitch when selecting option from autocomplete
  mouseDown(e) {
    if (e.target?.className.toString().includes('mat-mdc-option')) {
      e.preventDefault();
    }
  }
  clear() {
    this.searchForm.setValue('');
  }
  focus() {
    this.searchBar?.nativeElement?.focus();
  }
  blur() {
    this.searchBar?.nativeElement?.blur();
  }
  ngOnInit() {
    const ms = this.debouncedTime || DEBOUNCE_TIME;
    this.searchForm.valueChanges.pipe(debounceTime(ms), map(searchTerm => searchTerm.trim()), distinctUntilChanged(), map(searchTerm => searchTerm === undefined || searchTerm === null || searchTerm.length < this.minLength ? '' : searchTerm)).subscribe(searchTerm => {
      this.search.emit(searchTerm);
    });
    if (this.value) {
      this.value.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(value => {
        this.searchForm.setValue(value);
        if (this.blurOnValueChange) {
          this.blur();
        }
      });
    }
    if (this.matAutocomplete) {
      this.matAutocomplete.classList = ['cx-autocomplete-for-search-bar'];
    }
  }
  static {
    this.ɵfac = function CxSearchBarComponent_Factory(t) {
      return new (t || CxSearchBarComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: CxSearchBarComponent,
      selectors: [["cx-search-bar"]],
      viewQuery: function CxSearchBarComponent_Query(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵviewQuery(_c0, 5);
          i0.ɵɵviewQuery(MatAutocompleteTrigger, 5);
        }
        if (rf & 2) {
          let _t;
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.searchBar = _t.first);
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.autocompleteTrigger = _t.first);
        }
      },
      hostVars: 2,
      hostBindings: function CxSearchBarComponent_HostBindings(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵlistener("mousedown", function CxSearchBarComponent_mousedown_HostBindingHandler($event) {
            return ctx.mouseDown($event);
          }, false, i0.ɵɵresolveWindow);
        }
        if (rf & 2) {
          i0.ɵɵclassMap(ctx.class);
        }
      },
      inputs: {
        placeholder: "placeholder",
        value: "value",
        matAutocomplete: "matAutocomplete",
        debouncedTime: "debouncedTime",
        minLength: "minLength",
        blurOnValueChange: "blurOnValueChange",
        styleAsRegularInput: "styleAsRegularInput",
        hideIcon: "hideIcon",
        disabled: "disabled"
      },
      outputs: {
        search: "search"
      },
      ngContentSelectors: _c2,
      decls: 8,
      vars: 12,
      consts: [[1, "align-items-center", "cx-search-bar-wrapper"], ["class", "unclickable cx-search-bar-icon cx-search-bar-icon-search position-absolute", 3, "text-gray-50", 4, "ngIf"], [1, "cx-search-bar-container", "position-relative", "d-flex", "flex-row", "justify-content-between", "align-items-center"], ["data-cy", "cx-search-bar-input", "autocomplete", "off", 1, "cx-search-bar", "position-absolute", 3, "placeholder", "formControl", "matAutocomplete", "matAutocompleteDisabled"], ["searchBar", ""], ["mat-icon-button", "", "class", "cx-search-bar-icon cx-search-bar-icon-close position-absolute", 3, "mouseup", 4, "ngIf"], [1, "unclickable", "cx-search-bar-icon", "cx-search-bar-icon-search", "position-absolute"], ["mat-icon-button", "", 1, "cx-search-bar-icon", "cx-search-bar-icon-close", "position-absolute", 3, "mouseup"]],
      template: function CxSearchBarComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef(_c1);
          i0.ɵɵelementStart(0, "div", 0);
          i0.ɵɵtemplate(1, CxSearchBarComponent_mat_icon_1_Template, 2, 2, "mat-icon", 1);
          i0.ɵɵelementStart(2, "div", 2);
          i0.ɵɵelement(3, "input", 3, 4);
          i0.ɵɵtemplate(5, CxSearchBarComponent_button_5_Template, 3, 0, "button", 5);
          i0.ɵɵelementEnd();
          i0.ɵɵprojection(6);
          i0.ɵɵprojection(7, 1);
          i0.ɵɵelementEnd();
        }
        if (rf & 2) {
          i0.ɵɵadvance();
          i0.ɵɵproperty("ngIf", !ctx.hideIcon);
          i0.ɵɵadvance(2);
          i0.ɵɵclassProp("cx-search-bar-regular-input-mode", ctx.styleAsRegularInput)("cx-search-bar-padding-with-icon", !ctx.hideIcon)("cx-search-bar-padding-without-icon", ctx.hideIcon);
          i0.ɵɵproperty("placeholder", ctx.placeholder)("formControl", ctx.searchForm)("matAutocomplete", ctx.matAutocomplete)("matAutocompleteDisabled", ctx.matAutocomplete === null);
          i0.ɵɵadvance(2);
          i0.ɵɵproperty("ngIf", ctx.searchForm.value !== "" && ctx.searchForm.value !== undefined);
        }
      },
      dependencies: [i1.NgIf, i2.MatIconButton, i3.MatIcon, i4.MatAutocompleteTrigger, i5.DefaultValueAccessor, i5.NgControlStatus, i5.FormControlDirective],
      styles: [".cx-search-bar-wrapper[_ngcontent-%COMP%]{height:44px;max-width:100%;position:relative}.cx-search-bar-container[_ngcontent-%COMP%]{width:100%;height:44px}.cx-search-bar[_ngcontent-%COMP%]{background-color:#fff;height:44px;box-sizing:border-box;border:1px solid #737373;border-radius:8px;width:100%;font-size:16px;color:#737373}.cx-search-bar[_ngcontent-%COMP%]:focus{border:1px solid #00a97a}.cx-search-bar[_ngcontent-%COMP%]::placeholder{color:#7373737a}.cx-search-bar[_ngcontent-%COMP%]:focus::placeholder{color:transparent}.cx-search-bar-icon-search[_ngcontent-%COMP%]{margin-top:10px;margin-left:12px}.cx-search-bar-icon-close[_ngcontent-%COMP%]{top:4px;right:6px}.cx-search-bar-icon[_ngcontent-%COMP%]{z-index:1}.cx-search-bar-icon[_ngcontent-%COMP%]:not(.text-gray-50){color:#737373}.cx-search-bar-padding-with-icon[_ngcontent-%COMP%]{padding:3px 47px}.cx-search-bar-padding-without-icon[_ngcontent-%COMP%]{padding:10px 16px}.cx-search-bar-regular-input-mode[_ngcontent-%COMP%]{background-color:#ececec}.cx-search-bar-regular-input-mode[_ngcontent-%COMP%]:not(:focus, [_ngcontent-%COMP%]:hover){border-color:transparent}.cx-search-bar-regular-input-mode[_ngcontent-%COMP%]:hover{border-color:#73737329}.cx-search-bar-regular-input-mode[_ngcontent-%COMP%]:focus{border-color:#00a97a}.cx-search-bar[disabled][_ngcontent-%COMP%]{border-color:#b0b0b0}"]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CxSearchBarComponent, [{
    type: Component,
    args: [{
      selector: 'cx-search-bar',
      template: "<div class=\"align-items-center cx-search-bar-wrapper\">\n  <mat-icon\n    *ngIf=\"!hideIcon\"\n    class=\"unclickable cx-search-bar-icon cx-search-bar-icon-search position-absolute\"\n    [class.text-gray-50]=\"disabled\"\n    >search</mat-icon\n  >\n  <!-- Search wrapper -->\n  <div\n    class=\"cx-search-bar-container position-relative d-flex flex-row justify-content-between align-items-center\"\n  >\n    <!-- Search Input -->\n    <input\n      #searchBar\n      class=\"cx-search-bar position-absolute\"\n      data-cy=\"cx-search-bar-input\"\n      autocomplete=\"off\"\n      [class.cx-search-bar-regular-input-mode]=\"styleAsRegularInput\"\n      [class.cx-search-bar-padding-with-icon]=\"!hideIcon\"\n      [class.cx-search-bar-padding-without-icon]=\"hideIcon\"\n      [placeholder]=\"placeholder\"\n      [formControl]=\"searchForm\"\n      [matAutocomplete]=\"matAutocomplete\"\n      [matAutocompleteDisabled]=\"matAutocomplete === null\"\n    />\n\n    <!-- Clear input -->\n    <button\n      *ngIf=\"searchForm.value !== '' && searchForm.value !== undefined\"\n      mat-icon-button\n      class=\"cx-search-bar-icon cx-search-bar-icon-close position-absolute\"\n      (mouseup)=\"clear(); focus()\"\n    >\n      <mat-icon>close</mat-icon>\n    </button>\n  </div>\n  <ng-content></ng-content>\n  <ng-content select=\"mat-autocomplete\"></ng-content>\n</div>\n",
      styles: [".cx-search-bar-wrapper{height:44px;max-width:100%;position:relative}.cx-search-bar-container{width:100%;height:44px}.cx-search-bar{background-color:#fff;height:44px;box-sizing:border-box;border:1px solid #737373;border-radius:8px;width:100%;font-size:16px;color:#737373}.cx-search-bar:focus{border:1px solid #00a97a}.cx-search-bar::placeholder{color:#7373737a}.cx-search-bar:focus::placeholder{color:transparent}.cx-search-bar-icon-search{margin-top:10px;margin-left:12px}.cx-search-bar-icon-close{top:4px;right:6px}.cx-search-bar-icon{z-index:1}.cx-search-bar-icon:not(.text-gray-50){color:#737373}.cx-search-bar-padding-with-icon{padding:3px 47px}.cx-search-bar-padding-without-icon{padding:10px 16px}.cx-search-bar-regular-input-mode{background-color:#ececec}.cx-search-bar-regular-input-mode:not(:focus,:hover){border-color:transparent}.cx-search-bar-regular-input-mode:hover{border-color:#73737329}.cx-search-bar-regular-input-mode:focus{border-color:#00a97a}.cx-search-bar[disabled]{border-color:#b0b0b0}\n"]
    }]
  }], null, {
    class: [{
      type: HostBinding
    }],
    placeholder: [{
      type: Input
    }],
    value: [{
      type: Input
    }],
    matAutocomplete: [{
      type: Input
    }],
    debouncedTime: [{
      type: Input
    }],
    minLength: [{
      type: Input
    }],
    blurOnValueChange: [{
      type: Input
    }],
    styleAsRegularInput: [{
      type: Input
    }],
    hideIcon: [{
      type: Input
    }],
    search: [{
      type: Output
    }],
    searchBar: [{
      type: ViewChild,
      args: ['searchBar']
    }],
    autocompleteTrigger: [{
      type: ViewChild,
      args: [MatAutocompleteTrigger]
    }],
    disabled: [{
      type: Input
    }],
    mouseDown: [{
      type: HostListener,
      args: ['window:mousedown', ['$event']]
    }]
  });
})();
const components = [CxSearchBarComponent];
class CxSearchBarModule {
  static {
    this.ɵfac = function CxSearchBarModule_Factory(t) {
      return new (t || CxSearchBarModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: CxSearchBarModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      providers: [{
        provide: MAT_AUTOCOMPLETE_DEFAULT_OPTIONS,
        useValue: {
          autoActiveFirstOption: true
        }
      }],
      imports: [CommonModule, MatButtonModule, MatIconModule, MatAutocompleteModule, ReactiveFormsModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CxSearchBarModule, [{
    type: NgModule,
    args: [{
      declarations: [...components],
      imports: [CommonModule, MatButtonModule, MatIconModule, MatAutocompleteModule, ReactiveFormsModule],
      providers: [{
        provide: MAT_AUTOCOMPLETE_DEFAULT_OPTIONS,
        useValue: {
          autoActiveFirstOption: true
        }
      }],
      exports: [...components]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { CxSearchBarComponent, CxSearchBarModule };
