import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { Observable } from 'rxjs';
import { Notification } from './notification.model';

@Injectable({
  providedIn: 'root',
})
export class NotificationHttpService {
  private readonly baseUrl = `${environment.baseUrl}/notification`;

  constructor(private httpClient: HttpClient) {}

  getNotifications(): Observable<Notification[]> {
    return this.httpClient.get<Notification[]>(`${this.baseUrl}`);
  }

  markAsRead(notificationId: string): Observable<void> {
    return this.httpClient.post<void>(
      `${this.baseUrl}/${notificationId}/read`,
      {},
    );
  }
}
