import * as i3 from '@angular/common';
import { CommonModule } from '@angular/common';
import * as i0 from '@angular/core';
import { SecurityContext, Component, ChangeDetectionStrategy, Injectable, NgModule } from '@angular/core';
import * as i4 from '@angular/material/button';
import { MatButtonModule } from '@angular/material/button';
import * as i1 from '@angular/material/dialog';
import { MatDialogModule } from '@angular/material/dialog';
import * as i2 from '@angular/platform-browser';
function CxDialogComponent_div_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "div", 8);
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵclassProp("mb-m", ctx_r0.template);
    i0.ɵɵproperty("innerHTML", ctx_r0.messageTop, i0.ɵɵsanitizeHtml);
  }
}
function CxDialogComponent_div_5_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "div", 8);
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵclassProp("mt-m", ctx_r1.template);
    i0.ɵɵproperty("innerHTML", ctx_r1.messageBottom, i0.ɵɵsanitizeHtml);
  }
}
const _c0 = () => ["INVALID", true];
function CxDialogComponent_button_7_Template(rf, ctx) {
  if (rf & 1) {
    const _r6 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "button", 9);
    i0.ɵɵlistener("click", function CxDialogComponent_button_7_Template_button_click_0_listener() {
      const restoredCtx = i0.ɵɵrestoreView(_r6);
      const cancelAction_r4 = restoredCtx.$implicit;
      const ctx_r5 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(cancelAction_r4.clickAction ? cancelAction_r4.clickAction() : ctx_r5.dialogRef.close(cancelAction_r4.value));
    });
    i0.ɵɵpipe(1, "async");
    i0.ɵɵtext(2);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const cancelAction_r4 = ctx.$implicit;
    i0.ɵɵpropertyInterpolate("color", cancelAction_r4.color || "basic");
    i0.ɵɵproperty("disabled", i0.ɵɵpureFunction0(6, _c0).includes(i0.ɵɵpipeBind1(1, 4, cancelAction_r4.disabled)));
    i0.ɵɵattribute("data-cy", cancelAction_r4.dataAttr);
    i0.ɵɵadvance(2);
    i0.ɵɵtextInterpolate1(" ", cancelAction_r4.text, " ");
  }
}
function CxDialogComponent_button_8_Template(rf, ctx) {
  if (rf & 1) {
    const _r9 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "button", 10);
    i0.ɵɵlistener("click", function CxDialogComponent_button_8_Template_button_click_0_listener() {
      const restoredCtx = i0.ɵɵrestoreView(_r9);
      const confirmAction_r7 = restoredCtx.$implicit;
      const ctx_r8 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(confirmAction_r7.clickAction ? confirmAction_r7.clickAction() : ctx_r8.dialogRef.close(confirmAction_r7.value));
    });
    i0.ɵɵpipe(1, "async");
    i0.ɵɵtext(2);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const confirmAction_r7 = ctx.$implicit;
    i0.ɵɵpropertyInterpolate("color", confirmAction_r7.color || "accent");
    i0.ɵɵproperty("disabled", i0.ɵɵpureFunction0(6, _c0).includes(i0.ɵɵpipeBind1(1, 4, confirmAction_r7.disabled)));
    i0.ɵɵattribute("data-cy", confirmAction_r7.dataAttr);
    i0.ɵɵadvance(2);
    i0.ɵɵtextInterpolate1(" ", confirmAction_r7.text, " ");
  }
}
class CxDialogComponent {
  constructor(dialogRef, ds) {
    this.dialogRef = dialogRef;
    this.ds = ds;
  }
  ngOnInit() {
    this.messageTop = this.ds.sanitize(SecurityContext.HTML, this.messageTop);
    this.messageBottom = this.ds.sanitize(SecurityContext.HTML, this.messageBottom);
  }
  static {
    this.ɵfac = function CxDialogComponent_Factory(t) {
      return new (t || CxDialogComponent)(i0.ɵɵdirectiveInject(i1.MatDialogRef), i0.ɵɵdirectiveInject(i2.DomSanitizer));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: CxDialogComponent,
      selectors: [["cx-dialog"]],
      decls: 9,
      vars: 6,
      consts: [["mat-dialog-title", "", 1, "font-weight-bold"], ["mat-dialog-content", "", 1, "font-l"], [3, "mb-m", "innerHTML", 4, "ngIf"], [3, "ngTemplateOutlet"], [3, "mt-m", "innerHTML", 4, "ngIf"], ["mat-dialog-actions", "", 1, "mt-m", "d-flex", "justify-content-end"], ["mat-stroked-button", "", "class", "ml-sm", 3, "color", "disabled", "click", 4, "ngFor", "ngForOf"], ["mat-flat-button", "", "class", "ml-sm", 3, "color", "disabled", "click", 4, "ngFor", "ngForOf"], [3, "innerHTML"], ["mat-stroked-button", "", 1, "ml-sm", 3, "color", "disabled", "click"], ["mat-flat-button", "", 1, "ml-sm", 3, "color", "disabled", "click"]],
      template: function CxDialogComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelementStart(0, "h1", 0);
          i0.ɵɵtext(1);
          i0.ɵɵelementEnd();
          i0.ɵɵelementStart(2, "div", 1);
          i0.ɵɵtemplate(3, CxDialogComponent_div_3_Template, 1, 3, "div", 2);
          i0.ɵɵelementContainer(4, 3);
          i0.ɵɵtemplate(5, CxDialogComponent_div_5_Template, 1, 3, "div", 4);
          i0.ɵɵelementEnd();
          i0.ɵɵelementStart(6, "div", 5);
          i0.ɵɵtemplate(7, CxDialogComponent_button_7_Template, 3, 7, "button", 6)(8, CxDialogComponent_button_8_Template, 3, 7, "button", 7);
          i0.ɵɵelementEnd();
        }
        if (rf & 2) {
          i0.ɵɵadvance();
          i0.ɵɵtextInterpolate(ctx.title);
          i0.ɵɵadvance(2);
          i0.ɵɵproperty("ngIf", ctx.messageTop);
          i0.ɵɵadvance();
          i0.ɵɵproperty("ngTemplateOutlet", ctx.template);
          i0.ɵɵadvance();
          i0.ɵɵproperty("ngIf", ctx.messageBottom);
          i0.ɵɵadvance(2);
          i0.ɵɵproperty("ngForOf", ctx.cancelActions);
          i0.ɵɵadvance();
          i0.ɵɵproperty("ngForOf", ctx.confirmActions);
        }
      },
      dependencies: [i3.NgForOf, i3.NgIf, i3.NgTemplateOutlet, i1.MatDialogTitle, i1.MatDialogActions, i1.MatDialogContent, i4.MatButton, i3.AsyncPipe],
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CxDialogComponent, [{
    type: Component,
    args: [{
      selector: 'cx-dialog',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: "<h1 mat-dialog-title class=\"font-weight-bold\">{{ title }}</h1>\n<div mat-dialog-content class=\"font-l\">\n  <div\n    *ngIf=\"messageTop\"\n    [class.mb-m]=\"template\"\n    [innerHTML]=\"messageTop\"\n  ></div>\n  <ng-container [ngTemplateOutlet]=\"template\"></ng-container>\n  <div\n    *ngIf=\"messageBottom\"\n    [class.mt-m]=\"template\"\n    [innerHTML]=\"messageBottom\"\n  ></div>\n</div>\n<div mat-dialog-actions class=\"mt-m d-flex justify-content-end\">\n  <button\n    *ngFor=\"let cancelAction of cancelActions\"\n    mat-stroked-button\n    attr.data-cy=\"{{ cancelAction.dataAttr }}\"\n    color=\"{{ cancelAction.color || 'basic' }}\"\n    class=\"ml-sm\"\n    [disabled]=\"['INVALID', true].includes(cancelAction.disabled | async)\"\n    (click)=\"\n      cancelAction.clickAction\n        ? cancelAction.clickAction()\n        : dialogRef.close(cancelAction.value)\n    \"\n  >\n    {{ cancelAction.text }}\n  </button>\n  <button\n    *ngFor=\"let confirmAction of confirmActions\"\n    mat-flat-button\n    attr.data-cy=\"{{ confirmAction.dataAttr }}\"\n    color=\"{{ confirmAction.color || 'accent' }}\"\n    class=\"ml-sm\"\n    [disabled]=\"['INVALID', true].includes(confirmAction.disabled | async)\"\n    (click)=\"\n      confirmAction.clickAction\n        ? confirmAction.clickAction()\n        : dialogRef.close(confirmAction.value)\n    \"\n  >\n    {{ confirmAction.text }}\n  </button>\n</div>\n"
    }]
  }], () => [{
    type: i1.MatDialogRef
  }, {
    type: i2.DomSanitizer
  }], null);
})();
class CxDialogService {
  constructor(dialog) {
    this.dialog = dialog;
    this.width = '620px';
  }
  /**
   * B| Braun confirmation dialog which forces user to accept or decline
   *
   * @param title Dialog title
   * @param message Dialog message
   * @param confirmAction Text for the confirm action
   * @param cancelAction Text for the cancel action
   * @returns  Observable if accepted or declined
   */
  openConfirmDialog(title, message, confirmAction, cancelAction, disableClose = true, restoreFocus = false) {
    const dialogRef = this.dialog.open(CxDialogComponent, {
      autoFocus: false,
      disableClose,
      restoreFocus,
      width: this.width
    });
    dialogRef.componentInstance.title = title;
    dialogRef.componentInstance.messageBottom = message;
    dialogRef.componentInstance.confirmActions = [{
      text: confirmAction,
      value: true,
      dataAttr: 'cx-dialog-confirm'
    }];
    dialogRef.componentInstance.cancelActions = cancelAction && [{
      text: cancelAction,
      value: false,
      dataAttr: 'cx-dialog-cancel'
    }];
    return dialogRef.afterClosed();
  }
  /**
   * B| Braun deletion dialog which forces user to accept or decline
   *
   * @param title Dialog title
   * @param message Dialog message
   * @param deleteAction Text for the delete action
   * @param cancelAction Text for the cancel action
   * @returns  Observable if accepted or declined
   */
  openDeleteDialog(title, message, deleteAction, cancelAction, disableClose = true, restoreFocus = false) {
    const dialogRef = this.dialog.open(CxDialogComponent, {
      autoFocus: false,
      disableClose,
      restoreFocus,
      width: this.width
    });
    dialogRef.componentInstance.title = title;
    dialogRef.componentInstance.messageBottom = message;
    dialogRef.componentInstance.confirmActions = [{
      text: deleteAction,
      value: true,
      dataAttr: 'cx-dialog-confirm',
      color: 'warn'
    }];
    dialogRef.componentInstance.cancelActions = cancelAction && [{
      text: cancelAction,
      value: false,
      dataAttr: 'cx-dialog-cancel'
    }];
    return dialogRef.afterClosed();
  }
  openDialog(config) {
    const dialogRef = this.dialog.open(CxDialogComponent, {
      autoFocus: false,
      disableClose: config.disableClose || true,
      restoreFocus: config.restoreFocus || false,
      width: config.width || this.width,
      maxWidth: config.maxWidth
    });
    dialogRef.componentInstance.title = config.title;
    dialogRef.componentInstance.messageTop = config.messageTop;
    dialogRef.componentInstance.messageBottom = config.messageBottom;
    dialogRef.componentInstance.confirmActions = config.confirmButtons;
    dialogRef.componentInstance.cancelActions = config.cancelButtons;
    dialogRef.componentInstance.template = config.template;
    return dialogRef;
  }
  static {
    this.ɵfac = function CxDialogService_Factory(t) {
      return new (t || CxDialogService)(i0.ɵɵinject(i1.MatDialog));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: CxDialogService,
      factory: CxDialogService.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CxDialogService, [{
    type: Injectable
  }], () => [{
    type: i1.MatDialog
  }], null);
})();
const components = [CxDialogComponent];
const modules = [MatDialogModule, MatButtonModule];
const services = [CxDialogService];
class CxDialogModule {
  static {
    this.ɵfac = function CxDialogModule_Factory(t) {
      return new (t || CxDialogModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: CxDialogModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      providers: [...services],
      imports: [CommonModule, modules]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CxDialogModule, [{
    type: NgModule,
    args: [{
      declarations: [...components],
      imports: [CommonModule, ...modules],
      providers: [...services],
      exports: [...components]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { CxDialogComponent, CxDialogModule, CxDialogService };
