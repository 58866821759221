import { BasePortalOutlet, ComponentPortal } from '@angular/cdk/portal';
import * as i0 from '@angular/core';
import { Injectable, Inject, InjectionToken, signal, runInInjectionContext, SecurityContext, forwardRef, Component, ViewEncapsulation, HostBinding, HostListener, Injector, Optional, NgModule } from '@angular/core';
import * as i2 from '@angular/platform-browser';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as i1 from '@angular/cdk/overlay';
import * as i3 from '@angular/common';
import { DOCUMENT, CommonModule } from '@angular/common';
import { SNACK_BAR_DURATION, SNACK_BAR_DURATION_MULTILINE } from '@bbraun/cortex/shared';
import { trigger, state, style, transition, animate, keyframes } from '@angular/animations';
import { toObservable } from '@angular/core/rxjs-interop';
import * as i4 from '@angular/material/divider';
import { MatDividerModule } from '@angular/material/divider';
import * as i5 from '@angular/material/icon';
import { MatIconModule } from '@angular/material/icon';
import * as i6 from '@angular/material/button';
import { MatButtonModule } from '@angular/material/button';

/**
 * A PortalHost for attaching portals to an arbitrary DOM element outside of the Angular
 * application context.
 *
 * This is the only part of the portal core that directly touches the DOM.
 */
function CxSnackbarComponent_mat_icon_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "mat-icon", 8);
    i0.ɵɵtext(1, "info");
    i0.ɵɵelementEnd();
  }
}
function CxSnackbarComponent_mat_icon_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "mat-icon", 9);
    i0.ɵɵtext(1, "check_circle");
    i0.ɵɵelementEnd();
  }
}
function CxSnackbarComponent_mat_icon_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "mat-icon", 10);
    i0.ɵɵtext(1, "error");
    i0.ɵɵelementEnd();
  }
}
function CxSnackbarComponent_mat_icon_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "mat-icon", 11);
    i0.ɵɵtext(1, "warning");
    i0.ɵɵelementEnd();
  }
}
function CxSnackbarComponent_button_6_Template(rf, ctx) {
  if (rf & 1) {
    const _r7 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "button", 12);
    i0.ɵɵlistener("click", function CxSnackbarComponent_button_6_Template_button_click_0_listener() {
      i0.ɵɵrestoreView(_r7);
      const ctx_r6 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r6.onAction());
    });
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r4 = i0.ɵɵnextContext();
    i0.ɵɵclassProp("mr-xs", !ctx_r4.config.closeButton)("mr-m", ctx_r4.config.closeButton);
    i0.ɵɵproperty("color", ctx_r4.type);
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate1(" ", ctx_r4.actionLabel, " ");
  }
}
function CxSnackbarComponent_div_7_Template(rf, ctx) {
  if (rf & 1) {
    const _r9 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div", 13);
    i0.ɵɵelement(1, "mat-divider", 14);
    i0.ɵɵelementStart(2, "button", 15);
    i0.ɵɵlistener("click", function CxSnackbarComponent_div_7_Template_button_click_2_listener() {
      i0.ɵɵrestoreView(_r9);
      const ctx_r8 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r8.remove());
    });
    i0.ɵɵelementStart(3, "mat-icon");
    i0.ɵɵtext(4, "close");
    i0.ɵɵelementEnd()()();
  }
}
class DomPortalOutlet extends BasePortalOutlet {
  constructor(hostDomElement, viewContainerRef) {
    super();
    this.hostDomElement = hostDomElement;
    this.viewContainerRef = viewContainerRef;
  }
  /**
   * Attach the given ComponentPortal to DOM element using the ViewComponentRef provided by the component.
   * @param portal Portal to be attached
   */
  attachComponentPortal(portal) {
    const componentRef = this.viewContainerRef.createComponent(portal.component, {
      injector: portal.injector
    });
    this.setDisposeFn(() => {
      componentRef.destroy();
    });
    // At this point the component has been instantiated, so we move it to the location in the DOM
    // where we want it to be rendered.
    this.hostDomElement.insertBefore(this.getComponentRootNode(componentRef), this.hostDomElement.firstChild);
    return componentRef;
  }
  attachTemplatePortal() {
    return {};
  }
  /** Gets the root HTMLElement for an instantiated component. */
  getComponentRootNode(componentRef) {
    return componentRef.hostView.rootNodes[0];
  }
}

/**
 * Reference to an overlay that has been created with the Overlay service.
 * Used to manipulate or dispose of said overlay.
 */
class OverlayRef {
  constructor(portalHost) {
    this.portalHost = portalHost;
  }
  attach(portal) {
    return this.portalHost.attachComponentPortal(portal);
  }
  /**
   * Detaches an overlay from a portal.
   * @returns Resolves when the overlay has been detached.
   */
  detach() {
    return this.portalHost.detach();
  }
}

/**
 * Service to create Overlays. Overlays are dynamically added pieces of floating UI, meant to be
 * used as a low-level building building block for other components. Dialogs, tooltips, menus,
 * selects, etc. can all be built using overlays. The service should primarily be used by authors
 * of re-usable components rather than developers building end-user applications.
 *
 * An overlay *is* a PortalHost, so any kind of Portal can be loaded into one.
 */
class Overlay {
  constructor(overlayContainer, document) {
    this.overlayContainer = overlayContainer;
    this.document = document;
  }
  /**
   * Creates an overlay.
   * @returns A reference to the created overlay.
   */
  create() {
    // get existing pane if possible
    return this.createOverlayRef(this.getPaneElement());
  }
  getPaneElement() {
    if (!this.paneElement) {
      this.paneElement = this.createPaneElement();
    }
    return this.paneElement;
  }
  /**
   * Creates the DOM element for an overlay and appends it to the overlay container.
   * @returns Newly-created pane element
   */
  createPaneElement() {
    const pane = this.document.createElement('div');
    pane.id = 'snackbar-container';
    pane.classList.add('cx-snackbar-container');
    if (!this.paneElement) {
      this.overlayContainer.getContainerElement().appendChild(pane);
    }
    return pane;
  }
  /**
   * Create a DomPortalHost into which the overlay content can be loaded.
   * @param pane The DOM element to turn into a portal host.
   * @returns A portal host for the given DOM element.
   */
  createPortalHost(pane) {
    return new DomPortalOutlet(pane, this.viewContainerRef);
  }
  /**
   * Creates an OverlayRef for an overlay in the given DOM element.
   * @param pane DOM element for the overlay
   */
  createOverlayRef(pane) {
    return new OverlayRef(this.createPortalHost(pane));
  }
  static {
    this.ɵfac = function Overlay_Factory(t) {
      return new (t || Overlay)(i0.ɵɵinject(i1.OverlayContainer), i0.ɵɵinject(DOCUMENT));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: Overlay,
      factory: Overlay.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(Overlay, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: i1.OverlayContainer
  }, {
    type: undefined,
    decorators: [{
      type: Inject,
      args: [DOCUMENT]
    }]
  }], null);
})();
const DEFAULT_CONFIG = {
  maxOpened: 10,
  timeout: SNACK_BAR_DURATION,
  longTimeout: SNACK_BAR_DURATION_MULTILINE,
  closeButton: false
};
const SNACKBAR_CONFIG = new InjectionToken('SnackbarGlobalConfig');
/**
 * Everything a snackbar needs to launch
 */
class SnackbarPackage {
  constructor(snackbarId, config, message, actionLabel, snackbarType, snackbarRef) {
    this.snackbarId = snackbarId;
    this.config = config;
    this.message = message;
    this.actionLabel = actionLabel;
    this.snackbarType = snackbarType;
    this.snackbarRef = snackbarRef;
    this.onAction$ = new Subject();
    this.snackbarRef.afterClosed().subscribe(() => {
      this.onAction$.complete();
    });
  }
  triggerAction() {
    this.onAction$.next();
  }
  onAction() {
    return this.onAction$.asObservable();
  }
}
function slideAnimation() {
  return trigger('flyInOut', [state('inactive', style({
    opacity: 0
  })), transition('inactive => active', animate('350ms ease-out', keyframes([style({
    transform: 'translate(100%, 0)',
    opacity: 0
  }), style({
    transform: 'none',
    opacity: 1
  })]))), transition('active => removed', animate('400ms ease-out', keyframes([style({
    transform: 'translate(100%, 0)',
    opacity: 0
  })])))]);
}

/**
 * Reference to a snackbar opened via the snackbar service.
 */
class SnackbarRef {
  constructor(overlayRef, injector) {
    this.overlayRef = overlayRef;
    this.injector = injector;
    /** Subject for notifying the user that the snackbar has finished closing. */
    this.afterClosed$ = new Subject();
    /** triggered when snackbar is activated */
    this.active = signal(false);
    /** notifies the snackbar that it should close before the timeout */
    this.manualClose$ = new Subject();
    /** notifies the snackbar that it should reset the timeouts */
    this.resetTimeout$ = new Subject();
    runInInjectionContext(injector, () => {
      this.active$ = toObservable(this.active);
    });
  }
  manualClose() {
    this.manualClose$.next();
    this.manualClose$.complete();
  }
  manualClosed() {
    return this.manualClose$.asObservable();
  }
  timeoutReset() {
    return this.resetTimeout$.asObservable();
  }
  /**
   * Close the snackbar.
   */
  close() {
    this.overlayRef.detach();
    this.afterClosed$.next();
    this.manualClose$.next();
    this.afterClosed$.complete();
    this.manualClose$.complete();
    this.resetTimeout$.complete();
  }
  /** Gets an observable that is notified when the snackbar is finished closing. */
  afterClosed() {
    return this.afterClosed$.asObservable();
  }
  isInactive() {
    return !this.active();
  }
  activate() {
    this.active.set(true);
  }
  /** Gets an observable that is notified with true when the snackbar has started opening. */
  afterActivate() {
    return this.active$;
  }
}

/* eslint-disable no-underscore-dangle */
class CxSnackbarComponent {
  constructor(snackbarPackage, service, elementRef, ngZone, ds) {
    this.snackbarPackage = snackbarPackage;
    this.service = service;
    this.elementRef = elementRef;
    this.ngZone = ngZone;
    this.ds = ds;
    this.state = 'inactive';
    this.snackbarClasses = '';
    this.onDestroy$ = new Subject();
    this.message = snackbarPackage.message;
    this.type = snackbarPackage.snackbarType;
    this.actionLabel = snackbarPackage.actionLabel;
    this.config = snackbarPackage.config;
    this.snackbarTimeout = this.config.timeout;
    this.timeoutAfterMouseLeave = this.snackbarTimeout;
    this.snackbarClasses = `cx-snackbar-${snackbarPackage.snackbarType} cx-snackbar`;
    this.snackbarId = snackbarPackage.snackbarId;
  }
  /** hides component when waiting to be displayed */
  get displayStyle() {
    if (this.state === 'inactive') {
      return 'none';
    }
    return;
  }
  stickAround() {
    if (this.state === 'removed') {
      return;
    }
    clearTimeout(this.timeout);
    this.snackbarTimeout = 0;
  }
  delayedHideToast() {
    this.outsideTimeout(() => this.remove(), this.timeoutAfterMouseLeave);
    this.snackbarTimeout = this.timeoutAfterMouseLeave;
  }
  ngOnInit() {
    this.message = this.ds.sanitize(SecurityContext.HTML, this.message);
    this.snackbarPackage.snackbarRef.afterActivate().pipe(takeUntil(this.onDestroy$)).subscribe(value => {
      if (value) {
        this.activateSnackbar();
      }
    });
    this.snackbarPackage.snackbarRef.manualClosed().pipe(takeUntil(this.onDestroy$)).subscribe(() => {
      this.remove();
    });
    this.snackbarPackage.snackbarRef.timeoutReset().pipe(takeUntil(this.onDestroy$)).subscribe(() => {
      this.resetTimeout();
    });
  }
  ngOnDestroy() {
    this.onDestroy$.next();
    clearTimeout(this.timeout);
  }
  /**
   * tells service to remove this toast after animation time
   */
  remove() {
    if (this.state === 'removed') {
      return;
    }
    clearTimeout(this.timeout);
    this.state = 'removed';
    this.outsideTimeout(() => this.service.remove(this.snackbarPackage.snackbarId), 300);
  }
  onAction() {
    this.snackbarPackage.triggerAction();
    this.remove();
  }
  /**
   * activates toast and sets timeout
   */
  activateSnackbar() {
    this.state = 'active';
    setTimeout(() => {
      const applyLongTimeout = this.elementRef.nativeElement.getBoundingClientRect().height > 60;
      this.snackbarTimeout = applyLongTimeout ? this.config.longTimeout : this.snackbarTimeout;
      this.outsideTimeout(() => this.remove(), this.snackbarTimeout);
    });
  }
  resetTimeout() {
    clearTimeout(this.timeout);
    this.state = 'active';
  }
  outsideTimeout(func, timeout) {
    if (this.ngZone) {
      this.ngZone.runOutsideAngular(() => this.timeout = setTimeout(() => this.runInsideAngular(func), timeout));
    } else {
      this.timeout = setTimeout(() => func(), timeout);
    }
  }
  runInsideAngular(func) {
    if (this.ngZone) {
      this.ngZone.run(() => func());
    } else {
      func();
    }
  }
  static {
    this.ɵfac = function CxSnackbarComponent_Factory(t) {
      return new (t || CxSnackbarComponent)(i0.ɵɵdirectiveInject(SnackbarPackage), i0.ɵɵdirectiveInject(forwardRef(() => CxSnackbarService)), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone), i0.ɵɵdirectiveInject(i2.DomSanitizer));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: CxSnackbarComponent,
      selectors: [["cx-snackbar"]],
      hostVars: 6,
      hostBindings: function CxSnackbarComponent_HostBindings(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵlistener("mouseenter", function CxSnackbarComponent_mouseenter_HostBindingHandler() {
            return ctx.stickAround();
          })("mouseleave", function CxSnackbarComponent_mouseleave_HostBindingHandler() {
            return ctx.delayedHideToast();
          });
        }
        if (rf & 2) {
          i0.ɵɵhostProperty("id", ctx.snackbarId);
          i0.ɵɵsyntheticHostProperty("@flyInOut", ctx.state);
          i0.ɵɵclassMap(ctx.snackbarClasses);
          i0.ɵɵstyleProp("display", ctx.displayStyle);
        }
      },
      decls: 8,
      vars: 7,
      consts: [["class", "ml-s text-blue flex-shrink-0", 4, "ngIf"], ["class", "ml-s text-green flex-shrink-0", 4, "ngIf"], ["class", "ml-s text-yellow flex-shrink-0", 4, "ngIf"], ["class", "ml-s text-red flex-shrink-0", 4, "ngIf"], [1, "ml-s", "mr-sm", "my-sm", "flex-shrink-1", 3, "innerHTML"], [1, "ml-auto", "d-flex", "flex-shrink-0"], ["mat-button", "", "class", "mr-m", "data-cy", "cx-snackbar-button", 3, "mr-xs", "mr-m", "color", "click", 4, "ngIf"], ["class", "d-flex align-items-center mr-sm", 4, "ngIf"], [1, "ml-s", "text-blue", "flex-shrink-0"], [1, "ml-s", "text-green", "flex-shrink-0"], [1, "ml-s", "text-yellow", "flex-shrink-0"], [1, "ml-s", "text-red", "flex-shrink-0"], ["mat-button", "", "data-cy", "cx-snackbar-button", 1, "mr-m", 3, "color", "click"], [1, "d-flex", "align-items-center", "mr-sm"], ["vertical", "", 1, "mr-sm", "cx-snackbar-divider"], ["mat-icon-button", "", "data-cy", "cx-snackbar-close-button", 3, "click"]],
      template: function CxSnackbarComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵtemplate(0, CxSnackbarComponent_mat_icon_0_Template, 2, 0, "mat-icon", 0)(1, CxSnackbarComponent_mat_icon_1_Template, 2, 0, "mat-icon", 1)(2, CxSnackbarComponent_mat_icon_2_Template, 2, 0, "mat-icon", 2)(3, CxSnackbarComponent_mat_icon_3_Template, 2, 0, "mat-icon", 3);
          i0.ɵɵelement(4, "div", 4);
          i0.ɵɵelementStart(5, "div", 5);
          i0.ɵɵtemplate(6, CxSnackbarComponent_button_6_Template, 2, 6, "button", 6)(7, CxSnackbarComponent_div_7_Template, 5, 0, "div", 7);
          i0.ɵɵelementEnd();
        }
        if (rf & 2) {
          i0.ɵɵproperty("ngIf", ctx.type === "info");
          i0.ɵɵadvance();
          i0.ɵɵproperty("ngIf", ctx.type === "success");
          i0.ɵɵadvance();
          i0.ɵɵproperty("ngIf", ctx.type === "warning");
          i0.ɵɵadvance();
          i0.ɵɵproperty("ngIf", ctx.type === "error");
          i0.ɵɵadvance();
          i0.ɵɵproperty("innerHTML", ctx.message, i0.ɵɵsanitizeHtml);
          i0.ɵɵadvance(2);
          i0.ɵɵproperty("ngIf", ctx.actionLabel);
          i0.ɵɵadvance();
          i0.ɵɵproperty("ngIf", ctx.config.closeButton);
        }
      },
      dependencies: [i3.NgIf, i4.MatDivider, i5.MatIcon, i6.MatButton, i6.MatIconButton],
      styles: [".cx-snackbar-container{pointer-events:none;position:fixed;top:108px;right:32px;z-index:999999}.cx-snackbar{position:relative;display:flex;align-items:center;box-sizing:border-box;overflow:hidden;margin:0 0 12px;width:476px;min-height:60px;pointer-events:auto;border-radius:8px;box-shadow:0 8px 16px #20202029;word-break:break-word}.cx-snackbar-info{background:#e3f3ff;border-top:1px #2da0d3 solid;border-bottom:1px #2da0d3 solid;border-right:1px #2da0d3 solid}.cx-snackbar-info:after{content:\"\";width:4px;position:absolute;top:0;bottom:0;background-color:#2da0d3}.cx-snackbar-success{background:#e0f8f2;border-top:1px #00a97a solid;border-bottom:1px #00a97a solid;border-right:1px #00a97a solid}.cx-snackbar-success:after{content:\"\";width:4px;position:absolute;top:0;bottom:0;background-color:#00a97a}.cx-snackbar-warning{background:#f9f6ef;border-top:1px #fecc00 solid;border-bottom:1px #fecc00 solid;border-right:1px #fecc00 solid}.cx-snackbar-warning:after{content:\"\";width:4px;position:absolute;top:0;bottom:0;background-color:#fecc00}.cx-snackbar-error{background:#f6e8e8;border-top:1px #eb0000 solid;border-bottom:1px #eb0000 solid;border-right:1px #eb0000 solid}.cx-snackbar-error:after{content:\"\";width:4px;position:absolute;top:0;bottom:0;background-color:#eb0000}.cx-snackbar-divider{height:32px}\n"],
      encapsulation: 2,
      data: {
        animation: [slideAnimation()]
      }
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CxSnackbarComponent, [{
    type: Component,
    args: [{
      selector: 'cx-snackbar',
      encapsulation: ViewEncapsulation.None,
      animations: [slideAnimation()],
      template: "<mat-icon *ngIf=\"type === 'info'\" class=\"ml-s text-blue flex-shrink-0\"\n  >info</mat-icon\n>\n<mat-icon *ngIf=\"type === 'success'\" class=\"ml-s text-green flex-shrink-0\"\n  >check_circle</mat-icon\n>\n<mat-icon *ngIf=\"type === 'warning'\" class=\"ml-s text-yellow flex-shrink-0\"\n  >error</mat-icon\n>\n<mat-icon *ngIf=\"type === 'error'\" class=\"ml-s text-red flex-shrink-0\"\n  >warning</mat-icon\n>\n<div class=\"ml-s mr-sm my-sm flex-shrink-1 \" [innerHTML]=\"message\"></div>\n<div class=\"ml-auto d-flex flex-shrink-0\">\n  <button\n    *ngIf=\"actionLabel\"\n    mat-button\n    class=\"mr-m\"\n    [class.mr-xs]=\"!config.closeButton\"\n    [class.mr-m]=\"config.closeButton\"\n    data-cy=\"cx-snackbar-button\"\n    [color]=\"type\"\n    (click)=\"onAction()\"\n  >\n    {{ actionLabel }}\n  </button>\n  <div *ngIf=\"config.closeButton\" class=\"d-flex align-items-center mr-sm\">\n    <mat-divider vertical class=\"mr-sm cx-snackbar-divider\"></mat-divider>\n    <button\n      mat-icon-button\n      data-cy=\"cx-snackbar-close-button\"\n      (click)=\"remove()\"\n    >\n      <mat-icon>close</mat-icon>\n    </button>\n  </div>\n</div>\n",
      styles: [".cx-snackbar-container{pointer-events:none;position:fixed;top:108px;right:32px;z-index:999999}.cx-snackbar{position:relative;display:flex;align-items:center;box-sizing:border-box;overflow:hidden;margin:0 0 12px;width:476px;min-height:60px;pointer-events:auto;border-radius:8px;box-shadow:0 8px 16px #20202029;word-break:break-word}.cx-snackbar-info{background:#e3f3ff;border-top:1px #2da0d3 solid;border-bottom:1px #2da0d3 solid;border-right:1px #2da0d3 solid}.cx-snackbar-info:after{content:\"\";width:4px;position:absolute;top:0;bottom:0;background-color:#2da0d3}.cx-snackbar-success{background:#e0f8f2;border-top:1px #00a97a solid;border-bottom:1px #00a97a solid;border-right:1px #00a97a solid}.cx-snackbar-success:after{content:\"\";width:4px;position:absolute;top:0;bottom:0;background-color:#00a97a}.cx-snackbar-warning{background:#f9f6ef;border-top:1px #fecc00 solid;border-bottom:1px #fecc00 solid;border-right:1px #fecc00 solid}.cx-snackbar-warning:after{content:\"\";width:4px;position:absolute;top:0;bottom:0;background-color:#fecc00}.cx-snackbar-error{background:#f6e8e8;border-top:1px #eb0000 solid;border-bottom:1px #eb0000 solid;border-right:1px #eb0000 solid}.cx-snackbar-error:after{content:\"\";width:4px;position:absolute;top:0;bottom:0;background-color:#eb0000}.cx-snackbar-divider{height:32px}\n"]
    }]
  }], () => [{
    type: SnackbarPackage
  }, {
    type: undefined,
    decorators: [{
      type: Inject,
      args: [forwardRef(() => CxSnackbarService)]
    }]
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }, {
    type: i2.DomSanitizer
  }], {
    state: [{
      type: HostBinding,
      args: ['@flyInOut']
    }],
    snackbarClasses: [{
      type: HostBinding,
      args: ['class']
    }],
    snackbarId: [{
      type: HostBinding,
      args: ['id']
    }],
    displayStyle: [{
      type: HostBinding,
      args: ['style.display']
    }],
    stickAround: [{
      type: HostListener,
      args: ['mouseenter']
    }],
    delayedHideToast: [{
      type: HostListener,
      args: ['mouseleave']
    }]
  });
})();
class CxSnackbarService {
  constructor(customConfig, overlay, injector) {
    this.overlay = overlay;
    this.injector = injector;
    this.currentlyActive = 0;
    this.snackbars = [];
    this.index = 0;
    this.config = {
      ...DEFAULT_CONFIG,
      ...customConfig
    };
  }
  /** Show success snackbar */
  success(message, actionLabel) {
    return this.buildSnackbar('success', message, actionLabel);
  }
  /** Show error snackbar */
  error(message, actionLabel) {
    return this.buildSnackbar('error', message, actionLabel);
  }
  /** Show warning snackbar */
  warning(message, actionLabel) {
    return this.buildSnackbar('warning', message, actionLabel);
  }
  /** Show info snackbar */
  info(message, actionLabel) {
    return this.buildSnackbar('info', message, actionLabel);
  }
  /**
   * Remove and destroy a single snackbar by id
   */
  remove(snackbarId) {
    const found = this.findSnackbar(snackbarId);
    if (!found) {
      return false;
    }
    found.activeSnackbar.snackbarRef.close();
    this.snackbars.splice(found.index, 1);
    this.currentlyActive = this.currentlyActive - 1;
    if (!this.config.maxOpened || !this.snackbars.length) {
      return false;
    }
    if (this.currentlyActive < this.config.maxOpened && this.snackbars[this.currentlyActive]) {
      const p = this.snackbars[this.currentlyActive].snackbarRef;
      if (!p.isInactive()) {
        this.currentlyActive = this.currentlyActive + 1;
        p.activate();
      }
    }
    return true;
  }
  /**
   * Clear either all snackbars or a single snackbar by id
   */
  clear(snackbarId) {
    for (const snackbar of this.snackbars) {
      if (snackbarId !== undefined) {
        if (snackbar.snackbarId === snackbarId) {
          snackbar.snackbarRef.manualClose();
          return;
        }
      } else {
        snackbar.snackbarRef.manualClose();
      }
    }
  }
  setViewContainerRef(vcr) {
    this.overlay.viewContainerRef = vcr;
  }
  /**
   * Find snackbar object by id
   */
  findSnackbar(snackbarId) {
    for (let i = 0; i < this.snackbars.length; i++) {
      if (this.snackbars[i].snackbarId === snackbarId) {
        return {
          index: i,
          activeSnackbar: this.snackbars[i]
        };
      }
    }
    return null;
  }
  /**
   * Creates and attaches snackbar data to component
   * returns the active snackbar
   */
  buildSnackbar(snackbarType, message, actionLabel) {
    let keepInactive = false;
    if (this.config.maxOpened && this.currentlyActive >= this.config.maxOpened) {
      keepInactive = true;
      this.clear(this.snackbars[0].snackbarId);
    }
    const overlayRef = this.overlay.create();
    this.index = this.index + 1;
    const snackbarRef = new SnackbarRef(overlayRef, this.injector);
    const snackbarPackage = new SnackbarPackage(this.index, this.config, message, actionLabel, snackbarType, snackbarRef);
    const snackbarInjector = Injector.create({
      providers: [{
        provide: SnackbarPackage,
        useValue: snackbarPackage
      }]
    });
    const component = new ComponentPortal(CxSnackbarComponent, null, snackbarInjector);
    overlayRef.attach(component);
    const activeSnackbar = {
      onAction: () => snackbarPackage.onAction(),
      afterOpened: () => snackbarRef.afterActivate(),
      afterDismissed: () => snackbarRef.afterClosed(),
      snackbarId: this.index
    };
    const ins = {
      ...activeSnackbar,
      snackbarRef
    };
    if (!keepInactive) {
      this.currentlyActive = this.currentlyActive + 1;
      setTimeout(() => {
        ins.snackbarRef.activate();
      });
    }
    this.snackbars.push(ins);
    return activeSnackbar;
  }
  static {
    this.ɵfac = function CxSnackbarService_Factory(t) {
      return new (t || CxSnackbarService)(i0.ɵɵinject(SNACKBAR_CONFIG, 8), i0.ɵɵinject(Overlay), i0.ɵɵinject(i0.Injector));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: CxSnackbarService,
      factory: CxSnackbarService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CxSnackbarService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: undefined,
    decorators: [{
      type: Optional
    }, {
      type: Inject,
      args: [SNACKBAR_CONFIG]
    }]
  }, {
    type: Overlay
  }, {
    type: i0.Injector
  }], null);
})();
const components = [CxSnackbarComponent];
class CxSnackbarModule {
  static {
    this.ɵfac = function CxSnackbarModule_Factory(t) {
      return new (t || CxSnackbarModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: CxSnackbarModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      imports: [CommonModule, MatDividerModule, MatIconModule, MatButtonModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CxSnackbarModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule, MatDividerModule, MatIconModule, MatButtonModule],
      declarations: [...components]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { CxSnackbarComponent, CxSnackbarModule, CxSnackbarService, DEFAULT_CONFIG, SNACKBAR_CONFIG, SnackbarPackage, SnackbarRef };
