<cx-menu matMenuCssClasses="notification-menu">
  <button class="cx-menu-trigger cx-header-white-icon cx-badge" mat-icon-button>
    <mat-icon class="material-symbols-outlined white-medium-icon">notifications</mat-icon>
    <span class="cx-badge-notification-sm-icon" *ngIf="hasUnread"></span>
  </button>

  @if (notifications.length) {
    @for (notification of notifications; track notification) {
      <cx-menu-item [class.unread]="notification.unread"
                    (click)="navigateOnNotification(notification)"
                    (keydown.space)="navigateOnNotification(notification)">
        <div class="notification-menu-item">
          <mat-icon>sync_alt</mat-icon>
          <div>{{ 'UTILS.NOTIFICATION.CUSTOMER' | translate: { customer: notification.messageContext['customer'] } }}</div>
        </div>
      </cx-menu-item>
    }
  } @else {
    <div class="disabled-notification">
      <div class="notification-menu-item">
        <div>{{ 'UTILS.NOTIFICATION.EMPTY' | translate }}</div>
      </div>
    </div>
  }
</cx-menu>
