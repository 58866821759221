import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'hpm-header',
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent {
  @Input() title = '';
  @Input() showNotifications = false;
}
