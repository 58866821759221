import * as i1 from '@angular/common';
import { CommonModule } from '@angular/common';
import * as i0 from '@angular/core';
import { Component, EventEmitter, ChangeDetectionStrategy, Input, Output, NgModule } from '@angular/core';
import * as i3 from '@angular/material/button';
import { MatButtonModule } from '@angular/material/button';
import * as i2 from '@angular/material/icon';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { trigger, state, style, transition, animate } from '@angular/animations';
const _c0 = [[["cx-button-menu-list-item"]]];
const _c1 = ["cx-button-menu-list-item"];
function CxButtonMenuListItemComponent_mat_icon_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "mat-icon", 4);
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate(ctx_r0.icon);
  }
}
function CxButtonMenuListItemComponent_mat_icon_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "mat-icon", 5);
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵproperty("svgIcon", ctx_r1.svgIcon);
  }
}
function CxButtonMenuListItemComponent_span_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "span", 6);
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r2 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate(ctx_r2.text);
  }
}
function CxFloatingButtonComponent_div_0_ng_container_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0);
  }
}
function CxFloatingButtonComponent_div_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 3)(1, "div", 4);
    i0.ɵɵtemplate(2, CxFloatingButtonComponent_div_0_ng_container_2_Template, 1, 0, "ng-container", 5);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(3, "div", 6);
    i0.ɵɵprojection(4);
    i0.ɵɵelementEnd()();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    const _r3 = i0.ɵɵreference(3);
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("ngTemplateOutlet", _r3);
    i0.ɵɵadvance();
    i0.ɵɵclassProp("d-none", !ctx_r0.isMenuActive);
  }
}
function CxFloatingButtonComponent_ng_container_1_ng_container_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0);
  }
}
function CxFloatingButtonComponent_ng_container_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtemplate(1, CxFloatingButtonComponent_ng_container_1_ng_container_1_Template, 1, 0, "ng-container", 5);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    i0.ɵɵnextContext();
    const _r3 = i0.ɵɵreference(3);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngTemplateOutlet", _r3);
  }
}
const _c2 = (a0, a1, a2) => ({
  "cx-floating-button-green": a0,
  "cx-floating-button-expanded": a1,
  "cx-floating-button-no-expand": a2
});
const _c3 = a0 => ({
  value: a0
});
function CxFloatingButtonComponent_ng_template_2_Template(rf, ctx) {
  if (rf & 1) {
    const _r7 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "button", 7);
    i0.ɵɵlistener("click", function CxFloatingButtonComponent_ng_template_2_Template_button_click_0_listener() {
      i0.ɵɵrestoreView(_r7);
      const ctx_r6 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(!ctx_r6.secondaryButtonFlag && ctx_r6.primaryButtonClicked());
    })("mouseenter", function CxFloatingButtonComponent_ng_template_2_Template_button_mouseenter_0_listener() {
      i0.ɵɵrestoreView(_r7);
      const ctx_r8 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r8.primaryButtonMouseEnter());
    })("mouseleave", function CxFloatingButtonComponent_ng_template_2_Template_button_mouseleave_0_listener() {
      i0.ɵɵrestoreView(_r7);
      const ctx_r9 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r9.primaryButtonMouseLeave());
    });
    i0.ɵɵelementStart(1, "mat-icon", 8);
    i0.ɵɵtext(2);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(3, "span", 9);
    i0.ɵɵtext(4);
    i0.ɵɵelementEnd()();
  }
  if (rf & 2) {
    const ctx_r2 = i0.ɵɵnextContext();
    i0.ɵɵproperty("color", ctx_r2.secondaryButtonFlag ? "accent" : "primary")("ngClass", i0.ɵɵpureFunction3(6, _c2, ctx_r2.isMenuActive, ctx_r2.isHovered && ctx_r2.text && !ctx_r2.isMenuActive, ctx_r2.isMenuEnabled));
    i0.ɵɵadvance();
    i0.ɵɵproperty("@rotateAnimation", i0.ɵɵpureFunction1(10, _c3, ctx_r2.isMenuActive && ctx_r2.icon === "add"));
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate(ctx_r2.icon);
    i0.ɵɵadvance();
    i0.ɵɵproperty("@grow", ctx_r2.animationState());
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate(ctx_r2.text);
  }
}
const _c4 = [[["cx-button-menu-list"]]];
const _c5 = ["cx-button-menu-list"];
function CxSecondFloatingButtonComponent_div_0_ng_container_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0);
  }
}
function CxSecondFloatingButtonComponent_div_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 3)(1, "div", 4);
    i0.ɵɵtemplate(2, CxSecondFloatingButtonComponent_div_0_ng_container_2_Template, 1, 0, "ng-container", 5);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(3, "div", 6);
    i0.ɵɵprojection(4);
    i0.ɵɵelementEnd()();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    const _r3 = i0.ɵɵreference(3);
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("ngTemplateOutlet", _r3);
    i0.ɵɵadvance();
    i0.ɵɵclassProp("d-none", !ctx_r0.isMenuActive);
  }
}
function CxSecondFloatingButtonComponent_ng_container_1_ng_container_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0);
  }
}
function CxSecondFloatingButtonComponent_ng_container_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtemplate(1, CxSecondFloatingButtonComponent_ng_container_1_ng_container_1_Template, 1, 0, "ng-container", 5);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    i0.ɵɵnextContext();
    const _r3 = i0.ɵɵreference(3);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngTemplateOutlet", _r3);
  }
}
function CxSecondFloatingButtonComponent_ng_template_2_Template(rf, ctx) {
  if (rf & 1) {
    const _r7 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "button", 7);
    i0.ɵɵlistener("click", function CxSecondFloatingButtonComponent_ng_template_2_Template_button_click_0_listener() {
      i0.ɵɵrestoreView(_r7);
      const ctx_r6 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(!ctx_r6.secondaryButtonFlag && ctx_r6.primaryButtonClicked());
    })("mouseenter", function CxSecondFloatingButtonComponent_ng_template_2_Template_button_mouseenter_0_listener() {
      i0.ɵɵrestoreView(_r7);
      const ctx_r8 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r8.primaryButtonMouseEnter());
    })("mouseleave", function CxSecondFloatingButtonComponent_ng_template_2_Template_button_mouseleave_0_listener() {
      i0.ɵɵrestoreView(_r7);
      const ctx_r9 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r9.primaryButtonMouseLeave());
    });
    i0.ɵɵelementStart(1, "mat-icon", 8);
    i0.ɵɵtext(2);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(3, "span", 9);
    i0.ɵɵtext(4);
    i0.ɵɵelementEnd()();
  }
  if (rf & 2) {
    const ctx_r2 = i0.ɵɵnextContext();
    i0.ɵɵproperty("color", ctx_r2.secondaryButtonFlag ? "accent" : "primary")("ngClass", i0.ɵɵpureFunction3(6, _c2, ctx_r2.isMenuActive, ctx_r2.isHovered && ctx_r2.text && !ctx_r2.isMenuActive, ctx_r2.isMenuEnabled));
    i0.ɵɵadvance();
    i0.ɵɵproperty("@rotateAnimation", i0.ɵɵpureFunction1(10, _c3, ctx_r2.isMenuActive && ctx_r2.icon === "add"));
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate(ctx_r2.icon);
    i0.ɵɵadvance();
    i0.ɵɵproperty("@grow", ctx_r2.animationState());
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate(ctx_r2.text);
  }
}
const _c6 = ".cx-floating-button-z-index[_ngcontent-%COMP%], .cx-floating-button-menu[_ngcontent-%COMP%], .cx-floating-base-button[_ngcontent-%COMP%]{z-index:100}.cx-floating-base-button[_ngcontent-%COMP%]{position:fixed;right:96px;bottom:85px;padding:0 16px;width:auto}@media screen and (max-width: 1024px){.cx-floating-base-button[_ngcontent-%COMP%]{right:calc(10% + 65px)}}.cx-floating-button-menu[_ngcontent-%COMP%]{position:fixed;right:96px;bottom:173px}@media screen and (max-width: 1024px){.cx-floating-button-menu[_ngcontent-%COMP%]{right:calc(10% + 65px)}}.cx-floating-base-button-active[_ngcontent-%COMP%]{right:calc(15% + 73px)}@media screen and (max-width: 1024px){.cx-floating-base-button-active[_ngcontent-%COMP%]{right:calc(10% + 73px)}}.cx-floating-button-text[_ngcontent-%COMP%]{overflow:hidden;display:inline-block;white-space:nowrap}.cx-floating-button-text[_ngcontent-%COMP%]:not(:empty){padding:0 8px 0 16px;vertical-align:middle}";
class CxButtonMenuListComponent {
  static {
    this.ɵfac = function CxButtonMenuListComponent_Factory(t) {
      return new (t || CxButtonMenuListComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: CxButtonMenuListComponent,
      selectors: [["cx-button-menu-list"]],
      ngContentSelectors: _c1,
      decls: 1,
      vars: 0,
      template: function CxButtonMenuListComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef(_c0);
          i0.ɵɵprojection(0);
        }
      }
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CxButtonMenuListComponent, [{
    type: Component,
    args: [{
      selector: 'cx-button-menu-list',
      template: "<ng-content select=\"cx-button-menu-list-item\"></ng-content>\n"
    }]
  }], null, null);
})();
var AnimationState;
(function (AnimationState) {
  AnimationState["in"] = "in";
  AnimationState["out"] = "out";
})(AnimationState || (AnimationState = {}));
const ANIMATION = '0.2s ease-in-out';
const ANIMATION_DELAY = '0.2s 0.3s ease-in-out';
function growAnimation(animation) {
  return trigger('grow', [state(AnimationState.in, style({
    width: '*'
  })), state(AnimationState.out, style({
    padding: '0px',
    width: '0'
  })), transition(`${AnimationState.out} <=> ${AnimationState.in}`, animate(animation))]);
}
class CxButtonMenuListItemComponent {
  constructor() {
    this.clicked = new EventEmitter();
  }
  animationState() {
    return AnimationState.in;
  }
  static {
    this.ɵfac = function CxButtonMenuListItemComponent_Factory(t) {
      return new (t || CxButtonMenuListItemComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: CxButtonMenuListItemComponent,
      selectors: [["cx-button-menu-list-item"]],
      inputs: {
        icon: "icon",
        svgIcon: "svgIcon",
        text: "text"
      },
      outputs: {
        clicked: "clicked"
      },
      decls: 4,
      vars: 4,
      consts: [["mat-mini-fab", "", 1, "cx-floating-button-menu-item", "d-flex", "justify-content-center", "align-items-center", "ml-auto", "mt-ml", 3, "click"], ["class", "ml-2xs mr-s", 4, "ngIf"], ["class", "ml-xs mr-sm", 3, "svgIcon", 4, "ngIf"], ["class", "text-capitalize font-l cx-floating-button-text mr-s", 4, "ngIf"], [1, "ml-2xs", "mr-s"], [1, "ml-xs", "mr-sm", 3, "svgIcon"], [1, "text-capitalize", "font-l", "cx-floating-button-text", "mr-s"]],
      template: function CxButtonMenuListItemComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelementStart(0, "button", 0);
          i0.ɵɵlistener("click", function CxButtonMenuListItemComponent_Template_button_click_0_listener() {
            return ctx.clicked.emit();
          });
          i0.ɵɵtemplate(1, CxButtonMenuListItemComponent_mat_icon_1_Template, 2, 1, "mat-icon", 1)(2, CxButtonMenuListItemComponent_mat_icon_2_Template, 1, 1, "mat-icon", 2)(3, CxButtonMenuListItemComponent_span_3_Template, 2, 1, "span", 3);
          i0.ɵɵelementEnd();
        }
        if (rf & 2) {
          i0.ɵɵproperty("@grow", ctx.animationState());
          i0.ɵɵadvance();
          i0.ɵɵproperty("ngIf", ctx.icon);
          i0.ɵɵadvance();
          i0.ɵɵproperty("ngIf", ctx.svgIcon);
          i0.ɵɵadvance();
          i0.ɵɵproperty("ngIf", ctx.text);
        }
      },
      dependencies: [i1.NgIf, i2.MatIcon, i3.MatMiniFabButton],
      styles: [".cx-floating-button-menu-item[_ngcontent-%COMP%]{right:0;bottom:0;padding:0 8px;border-radius:56px;width:auto}"],
      data: {
        animation: [growAnimation(ANIMATION)]
      },
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CxButtonMenuListItemComponent, [{
    type: Component,
    args: [{
      selector: 'cx-button-menu-list-item',
      changeDetection: ChangeDetectionStrategy.OnPush,
      animations: [growAnimation(ANIMATION)],
      template: "<button\n  (click)=\"clicked.emit()\"\n  [@grow]=\"animationState()\"\n  mat-mini-fab\n  class=\"cx-floating-button-menu-item d-flex justify-content-center align-items-center ml-auto mt-ml\"\n>\n  <mat-icon *ngIf=\"icon\" class=\"ml-2xs mr-s\">{{ icon }}</mat-icon>\n  <mat-icon *ngIf=\"svgIcon\" class=\"ml-xs mr-sm\" [svgIcon]=\"svgIcon\"></mat-icon>\n  <span *ngIf=\"text\" class=\"text-capitalize font-l cx-floating-button-text mr-s\">{{\n    text\n  }}</span>\n</button>\n",
      styles: [".cx-floating-button-menu-item{right:0;bottom:0;padding:0 8px;border-radius:56px;width:auto}\n"]
    }]
  }], null, {
    icon: [{
      type: Input
    }],
    svgIcon: [{
      type: Input
    }],
    text: [{
      type: Input
    }],
    clicked: [{
      type: Output
    }]
  });
})();
function rotateAnimation() {
  return trigger('rotateAnimation', [state('false', style({
    transform: 'rotate(0deg)'
  })), state('true', style({
    transform: 'rotate(45deg)'
  })), transition('* <=> *', animate('200ms cubic-bezier(0.4, 0.0, 0.2, 1)'))]);
}
class CxFloatingButtonComponent {
  constructor() {
    this.icon = 'add';
    this.text = '';
    this.isMenuActive = false;
    this.isMenuEnabled = false;
    this.isMenuActiveChange = new EventEmitter();
    this.isHovered = false;
    this.secondaryButtonFlag = false;
  }
  primaryButtonMouseLeave() {
    this.isHovered = false;
  }
  primaryButtonMouseEnter() {
    this.isHovered = true;
  }
  primaryButtonClicked() {
    if (this.isMenuEnabled) {
      this.isMenuActive = !this.isMenuActive;
    }
    this.isMenuActiveChange.emit(this.isMenuActive);
  }
  animationState() {
    return this.isHovered ? AnimationState.in : AnimationState.out;
  }
  static {
    this.ɵfac = function CxFloatingButtonComponent_Factory(t) {
      return new (t || CxFloatingButtonComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: CxFloatingButtonComponent,
      selectors: [["cx-floating-button"]],
      inputs: {
        icon: "icon",
        text: "text",
        isMenuActive: "isMenuActive",
        isMenuEnabled: "isMenuEnabled"
      },
      outputs: {
        isMenuActiveChange: "isMenuActiveChange"
      },
      ngContentSelectors: _c5,
      decls: 4,
      vars: 2,
      consts: [["class", "flex-column-reverse align-items-center justify-content-center", 4, "ngIf"], [4, "ngIf"], ["mainButton", ""], [1, "flex-column-reverse", "align-items-center", "justify-content-center"], [1, "d-flex", "align-items-center", "justify-content-center"], [4, "ngTemplateOutlet"], [1, "d-flex", "align-items-end", "justify-content-center", "cx-floating-button-menu"], ["mat-fab", "", 1, "cx-floating-base-button", 3, "color", "ngClass", "click", "mouseenter", "mouseleave"], [1, "small-icon"], [1, "text-capitalize", "font-l", "cx-floating-button-text"]],
      template: function CxFloatingButtonComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef(_c4);
          i0.ɵɵtemplate(0, CxFloatingButtonComponent_div_0_Template, 5, 3, "div", 0)(1, CxFloatingButtonComponent_ng_container_1_Template, 2, 1, "ng-container", 1)(2, CxFloatingButtonComponent_ng_template_2_Template, 5, 12, "ng-template", null, 2, i0.ɵɵtemplateRefExtractor);
        }
        if (rf & 2) {
          i0.ɵɵproperty("ngIf", ctx.isMenuEnabled);
          i0.ɵɵadvance();
          i0.ɵɵproperty("ngIf", !ctx.isMenuEnabled);
        }
      },
      dependencies: [i1.NgClass, i1.NgIf, i1.NgTemplateOutlet, i2.MatIcon, i3.MatFabButton],
      styles: [".cx-floating-button-z-index[_ngcontent-%COMP%], .cx-floating-button-menu[_ngcontent-%COMP%], .cx-floating-base-button[_ngcontent-%COMP%]{z-index:100}.cx-floating-base-button[_ngcontent-%COMP%]{position:fixed;right:96px;bottom:85px;padding:0 16px;width:auto}@media screen and (max-width: 1024px){.cx-floating-base-button[_ngcontent-%COMP%]{right:calc(10% + 65px)}}.cx-floating-button-menu[_ngcontent-%COMP%]{position:fixed;right:96px;bottom:173px}@media screen and (max-width: 1024px){.cx-floating-button-menu[_ngcontent-%COMP%]{right:calc(10% + 65px)}}.cx-floating-base-button-active[_ngcontent-%COMP%]{right:calc(15% + 73px)}@media screen and (max-width: 1024px){.cx-floating-base-button-active[_ngcontent-%COMP%]{right:calc(10% + 73px)}}.cx-floating-button-text[_ngcontent-%COMP%]{overflow:hidden;display:inline-block;white-space:nowrap}.cx-floating-button-text[_ngcontent-%COMP%]:not(:empty){padding:0 8px 0 16px;vertical-align:middle}"],
      data: {
        animation: [growAnimation(ANIMATION_DELAY), rotateAnimation()]
      },
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CxFloatingButtonComponent, [{
    type: Component,
    args: [{
      selector: 'cx-floating-button',
      changeDetection: ChangeDetectionStrategy.OnPush,
      animations: [growAnimation(ANIMATION_DELAY), rotateAnimation()],
      template: "<div\n  *ngIf=\"isMenuEnabled\"\n  class=\"flex-column-reverse align-items-center justify-content-center\"\n>\n  <div class=\"d-flex align-items-center justify-content-center\">\n    <!-- Primary button -->\n    <ng-container *ngTemplateOutlet=\"mainButton\"></ng-container>\n  </div>\n\n  <div\n    [class.d-none]=\"!isMenuActive\"\n    class=\"d-flex align-items-end justify-content-center cx-floating-button-menu\"\n  >\n    <ng-content select=\"cx-button-menu-list\"></ng-content>\n  </div>\n</div>\n\n<!-- Secondary button -->\n<ng-container *ngIf=\"!isMenuEnabled\">\n  <ng-container *ngTemplateOutlet=\"mainButton\"></ng-container>\n</ng-container>\n\n<ng-template #mainButton>\n  <button\n    (click)=\"!secondaryButtonFlag && primaryButtonClicked()\"\n    (mouseenter)=\"primaryButtonMouseEnter()\"\n    (mouseleave)=\"primaryButtonMouseLeave()\"\n    [color]=\"secondaryButtonFlag ? 'accent' : 'primary'\"\n    class=\"cx-floating-base-button\"\n    mat-fab\n    [ngClass]=\"{\n      'cx-floating-button-green': isMenuActive,\n      'cx-floating-button-expanded': isHovered && text && !isMenuActive,\n      'cx-floating-button-no-expand': isMenuEnabled\n    }\"\n  >\n    <mat-icon\n      [@rotateAnimation]=\"{ value: isMenuActive && icon === 'add' }\"\n      class=\"small-icon\"\n      >{{ icon }}</mat-icon\n    >\n    <span\n      class=\"text-capitalize font-l cx-floating-button-text\"\n      [@grow]=\"animationState()\"\n      >{{ text }}</span\n    >\n  </button>\n</ng-template>\n",
      styles: [".cx-floating-button-z-index,.cx-floating-button-menu,.cx-floating-base-button{z-index:100}.cx-floating-base-button{position:fixed;right:96px;bottom:85px;padding:0 16px;width:auto}@media screen and (max-width: 1024px){.cx-floating-base-button{right:calc(10% + 65px)}}.cx-floating-button-menu{position:fixed;right:96px;bottom:173px}@media screen and (max-width: 1024px){.cx-floating-button-menu{right:calc(10% + 65px)}}.cx-floating-base-button-active{right:calc(15% + 73px)}@media screen and (max-width: 1024px){.cx-floating-base-button-active{right:calc(10% + 73px)}}.cx-floating-button-text{overflow:hidden;display:inline-block;white-space:nowrap}.cx-floating-button-text:not(:empty){padding:0 8px 0 16px;vertical-align:middle}\n"]
    }]
  }], null, {
    icon: [{
      type: Input
    }],
    text: [{
      type: Input
    }],
    isMenuActive: [{
      type: Input
    }],
    isMenuEnabled: [{
      type: Input
    }],
    isMenuActiveChange: [{
      type: Output
    }]
  });
})();
class CxSecondFloatingButtonComponent extends CxFloatingButtonComponent {
  constructor() {
    super(...arguments);
    this.secondaryButtonFlag = true;
  }
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵCxSecondFloatingButtonComponent_BaseFactory;
      return function CxSecondFloatingButtonComponent_Factory(t) {
        return (ɵCxSecondFloatingButtonComponent_BaseFactory || (ɵCxSecondFloatingButtonComponent_BaseFactory = i0.ɵɵgetInheritedFactory(CxSecondFloatingButtonComponent)))(t || CxSecondFloatingButtonComponent);
      };
    })();
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: CxSecondFloatingButtonComponent,
      selectors: [["cx-second-floating-button"]],
      features: [i0.ɵɵInheritDefinitionFeature],
      ngContentSelectors: _c5,
      decls: 4,
      vars: 2,
      consts: [["class", "flex-column-reverse align-items-center justify-content-center", 4, "ngIf"], [4, "ngIf"], ["mainButton", ""], [1, "flex-column-reverse", "align-items-center", "justify-content-center"], [1, "d-flex", "align-items-center", "justify-content-center"], [4, "ngTemplateOutlet"], [1, "d-flex", "align-items-end", "justify-content-center", "cx-floating-button-menu"], ["mat-fab", "", 1, "cx-floating-base-button", 3, "color", "ngClass", "click", "mouseenter", "mouseleave"], [1, "small-icon"], [1, "text-capitalize", "font-l", "cx-floating-button-text"]],
      template: function CxSecondFloatingButtonComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef(_c4);
          i0.ɵɵtemplate(0, CxSecondFloatingButtonComponent_div_0_Template, 5, 3, "div", 0)(1, CxSecondFloatingButtonComponent_ng_container_1_Template, 2, 1, "ng-container", 1)(2, CxSecondFloatingButtonComponent_ng_template_2_Template, 5, 12, "ng-template", null, 2, i0.ɵɵtemplateRefExtractor);
        }
        if (rf & 2) {
          i0.ɵɵproperty("ngIf", ctx.isMenuEnabled);
          i0.ɵɵadvance();
          i0.ɵɵproperty("ngIf", !ctx.isMenuEnabled);
        }
      },
      dependencies: [i1.NgClass, i1.NgIf, i1.NgTemplateOutlet, i2.MatIcon, i3.MatFabButton],
      styles: [_c6, ".cx-floating-base-button[_ngcontent-%COMP%]{bottom:155px}.cx-floating-button-menu[_ngcontent-%COMP%]{bottom:215px}"],
      data: {
        animation: [growAnimation(ANIMATION_DELAY)]
      },
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CxSecondFloatingButtonComponent, [{
    type: Component,
    args: [{
      selector: 'cx-second-floating-button',
      changeDetection: ChangeDetectionStrategy.OnPush,
      animations: [growAnimation(ANIMATION_DELAY)],
      template: "<div\n  *ngIf=\"isMenuEnabled\"\n  class=\"flex-column-reverse align-items-center justify-content-center\"\n>\n  <div class=\"d-flex align-items-center justify-content-center\">\n    <!-- Primary button -->\n    <ng-container *ngTemplateOutlet=\"mainButton\"></ng-container>\n  </div>\n\n  <div\n    [class.d-none]=\"!isMenuActive\"\n    class=\"d-flex align-items-end justify-content-center cx-floating-button-menu\"\n  >\n    <ng-content select=\"cx-button-menu-list\"></ng-content>\n  </div>\n</div>\n\n<!-- Secondary button -->\n<ng-container *ngIf=\"!isMenuEnabled\">\n  <ng-container *ngTemplateOutlet=\"mainButton\"></ng-container>\n</ng-container>\n\n<ng-template #mainButton>\n  <button\n    (click)=\"!secondaryButtonFlag && primaryButtonClicked()\"\n    (mouseenter)=\"primaryButtonMouseEnter()\"\n    (mouseleave)=\"primaryButtonMouseLeave()\"\n    [color]=\"secondaryButtonFlag ? 'accent' : 'primary'\"\n    class=\"cx-floating-base-button\"\n    mat-fab\n    [ngClass]=\"{\n      'cx-floating-button-green': isMenuActive,\n      'cx-floating-button-expanded': isHovered && text && !isMenuActive,\n      'cx-floating-button-no-expand': isMenuEnabled\n    }\"\n  >\n    <mat-icon\n      [@rotateAnimation]=\"{ value: isMenuActive && icon === 'add' }\"\n      class=\"small-icon\"\n      >{{ icon }}</mat-icon\n    >\n    <span\n      class=\"text-capitalize font-l cx-floating-button-text\"\n      [@grow]=\"animationState()\"\n      >{{ text }}</span\n    >\n  </button>\n</ng-template>\n",
      styles: [".cx-floating-button-z-index,.cx-floating-button-menu,.cx-floating-base-button{z-index:100}.cx-floating-base-button{position:fixed;right:96px;bottom:85px;padding:0 16px;width:auto}@media screen and (max-width: 1024px){.cx-floating-base-button{right:calc(10% + 65px)}}.cx-floating-button-menu{position:fixed;right:96px;bottom:173px}@media screen and (max-width: 1024px){.cx-floating-button-menu{right:calc(10% + 65px)}}.cx-floating-base-button-active{right:calc(15% + 73px)}@media screen and (max-width: 1024px){.cx-floating-base-button-active{right:calc(10% + 73px)}}.cx-floating-button-text{overflow:hidden;display:inline-block;white-space:nowrap}.cx-floating-button-text:not(:empty){padding:0 8px 0 16px;vertical-align:middle}\n", ".cx-floating-base-button{bottom:155px}.cx-floating-button-menu{bottom:215px}\n"]
    }]
  }], null, null);
})();
const components = [CxFloatingButtonComponent, CxSecondFloatingButtonComponent, CxButtonMenuListComponent, CxButtonMenuListItemComponent];
const modules = [MatIconModule, MatButtonModule, MatTooltipModule];
class CxFloatingButtonModule {
  static {
    this.ɵfac = function CxFloatingButtonModule_Factory(t) {
      return new (t || CxFloatingButtonModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: CxFloatingButtonModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      imports: [CommonModule, modules, MatIconModule, MatButtonModule, MatTooltipModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CxFloatingButtonModule, [{
    type: NgModule,
    args: [{
      declarations: [...components],
      imports: [CommonModule, ...modules],
      exports: [...components, ...modules]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { CxButtonMenuListComponent, CxButtonMenuListItemComponent, CxFloatingButtonComponent, CxFloatingButtonModule, CxSecondFloatingButtonComponent };
