import * as i1 from '@angular/common/http';
import { HttpHeaders, HttpClientModule } from '@angular/common/http';
import * as i0 from '@angular/core';
import { Directive, Input, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
const convertBlobToBase64 = blob => new Promise(resolve => {
  const reader = new FileReader();
  reader.onload = () => {
    resolve(reader.result);
  };
  reader.readAsDataURL(blob);
});
class CxSecureImageDirective {
  constructor(renderer, el, http) {
    this.renderer = renderer;
    this.el = el;
    this.http = http;
  }
  ngOnChanges() {
    if (this.cxImgToken) {
      // Tell that XHR is going to receive an image as response, so it can be then converted to blob, and also provide your token
      const headers = new HttpHeaders({
        Authorization: 'Bearer ' + this.cxImgToken
      });
      this.http.get(this.url, {
        headers,
        responseType: 'blob'
      }).subscribe({
        next: blob => {
          convertBlobToBase64(blob).then(base64 => {
            this.renderer.setAttribute(this.el.nativeElement, 'src', base64);
          });
        },
        error: err => {
          this.renderer.setAttribute(this.el.nativeElement, 'src', this.url);
          console.error(err);
        }
      });
    } else {
      this.renderer.setAttribute(this.el.nativeElement, 'src', this.url);
    }
  }
  static {
    this.ɵfac = function CxSecureImageDirective_Factory(t) {
      return new (t || CxSecureImageDirective)(i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i1.HttpClient));
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: CxSecureImageDirective,
      selectors: [["img", "cxImgToken", ""]],
      inputs: {
        cxImgToken: "cxImgToken",
        url: "url"
      },
      features: [i0.ɵɵNgOnChangesFeature]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CxSecureImageDirective, [{
    type: Directive,
    args: [{
      selector: 'img[cxImgToken]'
    }]
  }], () => [{
    type: i0.Renderer2
  }, {
    type: i0.ElementRef
  }, {
    type: i1.HttpClient
  }], {
    cxImgToken: [{
      type: Input
    }],
    url: [{
      type: Input
    }]
  });
})();
class CxSecureImageModule {
  static {
    this.ɵfac = function CxSecureImageModule_Factory(t) {
      return new (t || CxSecureImageModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: CxSecureImageModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      imports: [CommonModule, HttpClientModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CxSecureImageModule, [{
    type: NgModule,
    args: [{
      declarations: [CxSecureImageDirective],
      imports: [CommonModule, HttpClientModule],
      exports: [CxSecureImageDirective]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { CxSecureImageDirective, CxSecureImageModule };
