import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { NotificationHttpService } from './notification-http.service';
import { Subject, takeUntil } from 'rxjs';
import { Notification } from './notification.model';
import { Router } from '@angular/router';

@Component({
  selector: 'hpm-notification',
  templateUrl: './notification.component.html',
  styleUrl: './notification.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class NotificationComponent implements OnInit, OnDestroy {
  private onDestroy$: Subject<void> = new Subject<void>();
  notifications: Notification[] = [];
  hasUnread = false;

  constructor(
    private notificationService: NotificationHttpService,
    private router: Router,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.notificationService
      .getNotifications()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((notifications) => {
        this.notifications = notifications;
        this.hasUnread = this.notifications.some(
          (notification) => notification.unread,
        );
        this.cdr.detectChanges();
      });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  navigateOnNotification(notification: Notification): void {
    this.notificationService
      .markAsRead(notification.id)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => {
        this.router.navigate(['order/', notification.orderId]);
      });
  }
}
