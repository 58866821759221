<cx-header>
  <cx-header-title>
    <div class="row gap">
      <ng-content select=".linkback"></ng-content>
      {{ title | translate }}
    </div>
  </cx-header-title>
  <cx-header-quickbar>
    <ng-content select=".header-menu"></ng-content>
    @if (showNotifications) {
      <hpm-notification></hpm-notification>
    }
    <hpm-user-profile></hpm-user-profile>
  </cx-header-quickbar>
</cx-header>
