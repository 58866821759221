import {HttpParams} from "@angular/common/http";

export class PageRequest {
  pageSize = 0;
  pageNumber = 0;
  sort: { id: string; start: "asc" | "desc" | "" };
  filter = new Map<string, string[]>;

  constructor(request: {
    page: number,
    size: number,
    filter?: Map<string, string[]>,
    sort?: { id: string, start: 'asc' | 'desc' }
  }) {
    this.pageNumber = request.page;
    this.pageSize = request.size;
    if (request.filter) {
      this.filter = request.filter;
    }
    if (request.sort) {
      this.sort = request.sort;
    } else {
      this.sort = {id: 'lastChangeDate', start: 'desc'};
    }
  }


  toParams(): HttpParams {
    let params = new HttpParams()
      .append('page', this.pageNumber.toString())
      .append('size', this.pageSize.toString());
    if (this.sort) {
      params = params.append('sort', this.sort.id + ',' + this.sort.start);
    }
    params = this.addFilterToParams(params, this.filter);
    return params;
  }

  addFilterToParams(params: HttpParams, filter: Map<string, string[]>): HttpParams {
    filter.forEach((filterValue, field) => {
      if (filterValue.length > 0) {
        filterValue.forEach(value => params = params.append(`filter.${field}`, `${value}`));
      }
    });
    return params;

  }
}
