import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { AuthorizedUser } from '../../shared/model/user.model';
import { UserHttpService } from '../../shared/service/user-http.service';
import { Subject, takeUntil } from 'rxjs';
import { MsalService } from '@azure/msal-angular';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'hpm-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrl: './user-profile.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserProfileComponent implements OnInit, OnDestroy {
  currentUser: AuthorizedUser | null = null;
  private onDestroy$: Subject<void> = new Subject<void>();

  constructor(
    private userService: UserHttpService,
    private cdr: ChangeDetectorRef,
    private authService: MsalService,
  ) {}

  ngOnInit(): void {
    this.loadCurrentUser();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  private loadCurrentUser(): void {
    this.userService
      .getCurrentUser()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((currentUser) => {
        if (currentUser !== null) {
          this.currentUser = currentUser;
          this.cdr.detectChanges();
        }
      });
  }

  logout(): void {
    this.authService.logout({
      idTokenHint: this.authService.instance.getAllAccounts()[0].idToken,
    });
  }

  navigateToGPP(): void {
    window.open(environment.gppURL, '_blank')?.focus();
  }
}
