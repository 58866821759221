import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { catchError, Observable, of, shareReplay } from 'rxjs';
import { AuthorizedUser, User } from '../model/user.model';

@Injectable({
  providedIn: 'root',
})
export class UserHttpService {
  private readonly userBaseUrl = `${environment.baseUrl}/user`;
  private currentUser$: Observable<AuthorizedUser | null> | null = null;

  constructor(private httpClient: HttpClient) {}

  getCurrentUser(): Observable<AuthorizedUser | null> {
    if (!this.currentUser$) {
      this.currentUser$ = this.loadCurrentUser().pipe(
        shareReplay(1),
        catchError((err) => {
          console.log(err);
          return of(null);
        }),
      );
    }
    return this.currentUser$;
  }

  private loadCurrentUser(): Observable<AuthorizedUser | null> {
    return this.httpClient.get<AuthorizedUser>(
      `${this.userBaseUrl}/currentUser`,
    );
  }

  getUserList(): Observable<User[]> {
    return this.httpClient.get<User[]>(`${this.userBaseUrl}/userlist`);
  }
}
