import { Pipe, PipeTransform } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { LocalizedString } from '../../model/localizedString';

@Pipe({
  name: 'localeString',
  standalone: true,
})
export class LocaleStringPipe implements PipeTransform {
  private selectedLocale = `${environment.selectedLocale}`;

  transform(value: LocalizedString): string {
    // @ts-expect-error //selectedLocale defines variable that should be loaded
    let localizedString = value[this.selectedLocale];
    if (!localizedString) {
      localizedString = value['de'];
    }
    return localizedString;
  }
}
