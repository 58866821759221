import * as i0 from '@angular/core';
import { Component, Input } from '@angular/core';
import * as i1 from '@angular/material/expansion';
import { MatExpansionModule } from '@angular/material/expansion';
function CxExpansionPanelComponent_Conditional_4_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "span");
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate(ctx_r0.text);
  }
}
function CxExpansionPanelComponent_Conditional_5_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵprojection(0);
  }
}
const _c0 = ["*"];
class CxExpansionPanelComponent {
  static {
    this.ɵfac = function CxExpansionPanelComponent_Factory(t) {
      return new (t || CxExpansionPanelComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: CxExpansionPanelComponent,
      selectors: [["cx-expansion-panel"]],
      inputs: {
        panelTitle: "panelTitle",
        text: "text"
      },
      standalone: true,
      features: [i0.ɵɵStandaloneFeature],
      ngContentSelectors: _c0,
      decls: 6,
      vars: 2,
      consts: [[1, "cx-expansion-panel"]],
      template: function CxExpansionPanelComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵelementStart(0, "mat-expansion-panel", 0)(1, "mat-expansion-panel-header")(2, "mat-panel-title");
          i0.ɵɵtext(3);
          i0.ɵɵelementEnd()();
          i0.ɵɵtemplate(4, CxExpansionPanelComponent_Conditional_4_Template, 2, 1, "span")(5, CxExpansionPanelComponent_Conditional_5_Template, 1, 0);
          i0.ɵɵelementEnd();
        }
        if (rf & 2) {
          i0.ɵɵadvance(3);
          i0.ɵɵtextInterpolate1(" ", ctx.panelTitle, " ");
          i0.ɵɵadvance();
          i0.ɵɵconditional(4, ctx.text ? 4 : 5);
        }
      },
      dependencies: [MatExpansionModule, i1.MatExpansionPanel, i1.MatExpansionPanelHeader, i1.MatExpansionPanelTitle]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CxExpansionPanelComponent, [{
    type: Component,
    args: [{
      selector: 'cx-expansion-panel',
      standalone: true,
      imports: [MatExpansionModule],
      template: "<mat-expansion-panel class=\"cx-expansion-panel\">\n  <mat-expansion-panel-header>\n    <mat-panel-title>\n      {{panelTitle}}\n    </mat-panel-title>\n  </mat-expansion-panel-header>\n  @if (text) {\n    <span>{{text}}</span>\n  } @else {\n    <ng-content />\n  }\n</mat-expansion-panel>\n"
    }]
  }], null, {
    panelTitle: [{
      type: Input
    }],
    text: [{
      type: Input
    }]
  });
})();

/**
 * Generated bundle index. Do not edit.
 */

export { CxExpansionPanelComponent };
