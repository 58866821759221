<cx-header>
  <cx-header-title>HPM 3.0</cx-header-title>
  <cx-header-quickbar>
    <hpm-user-profile></hpm-user-profile>
  </cx-header-quickbar>
</cx-header>
<cx-message>
  <cx-message-title>{{ headline | translate }}</cx-message-title>
  <cx-message-text>{{ description | translate }}</cx-message-text>
  <cx-message-button *ngIf="buttonText !== ''" [buttonText]="buttonText | translate" routerLink="/"></cx-message-button>
</cx-message>
