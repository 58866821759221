import * as i0 from '@angular/core';
import { EventEmitter, SecurityContext, Component, Input, Output, NgModule } from '@angular/core';
import * as i1 from '@angular/platform-browser';
import * as i2 from '@angular/common';
import { CommonModule } from '@angular/common';
import * as i3 from '@angular/material/icon';
import { MatIconModule } from '@angular/material/icon';
import * as i4 from '@angular/material/button';
import { MatButtonModule } from '@angular/material/button';
import * as i5 from '@angular/material/divider';
import { MatDividerModule } from '@angular/material/divider';
function CxBannerComponent_h3_4_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "h3", 8);
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate(ctx_r0.title);
  }
}
function CxBannerComponent_Conditional_6_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "span", 4);
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵproperty("innerHTML", ctx_r1.message, i0.ɵɵsanitizeHtml);
  }
}
function CxBannerComponent_Conditional_7_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵprojection(0);
  }
}
function CxBannerComponent_button_9_Template(rf, ctx) {
  if (rf & 1) {
    const _r6 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "button", 9);
    i0.ɵɵlistener("click", function CxBannerComponent_button_9_Template_button_click_0_listener() {
      i0.ɵɵrestoreView(_r6);
      const ctx_r5 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r5.onAction());
    });
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r3 = i0.ɵɵnextContext();
    i0.ɵɵclassProp("mr-xs", !ctx_r3.dismissable)("mr-m", ctx_r3.dismissable);
    i0.ɵɵproperty("color", ctx_r3.type);
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate1(" ", ctx_r3.actionLabel, " ");
  }
}
function CxBannerComponent_div_10_Template(rf, ctx) {
  if (rf & 1) {
    const _r8 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div", 10);
    i0.ɵɵelement(1, "mat-divider", 11);
    i0.ɵɵelementStart(2, "button", 12);
    i0.ɵɵlistener("click", function CxBannerComponent_div_10_Template_button_click_2_listener() {
      i0.ɵɵrestoreView(_r8);
      const ctx_r7 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r7.onClose());
    });
    i0.ɵɵelementStart(3, "mat-icon");
    i0.ɵɵtext(4, "close");
    i0.ɵɵelementEnd()()();
  }
}
const _c0 = ["*"];
class CxBannerComponent {
  constructor(ds) {
    this.ds = ds;
    this.type = 'info';
    this.icon = 'info';
    this.dismissable = false;
    this.actionEvent = new EventEmitter();
    this.closeEvent = new EventEmitter();
  }
  ngOnInit() {
    this.message = this.ds.sanitize(SecurityContext.HTML, this.message);
  }
  onAction() {
    this.actionEvent.emit();
  }
  onClose() {
    this.closeEvent.emit();
  }
  static {
    this.ɵfac = function CxBannerComponent_Factory(t) {
      return new (t || CxBannerComponent)(i0.ɵɵdirectiveInject(i1.DomSanitizer));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: CxBannerComponent,
      selectors: [["cx-banner"]],
      inputs: {
        message: "message",
        title: "title",
        actionLabel: "actionLabel",
        type: "type",
        icon: "icon",
        dismissable: "dismissable"
      },
      outputs: {
        actionEvent: "actionEvent",
        closeEvent: "closeEvent"
      },
      ngContentSelectors: _c0,
      decls: 11,
      vars: 12,
      consts: [[1, "cx-banner-icon", "mx-m", "flex-shrink-0"], [1, "pr-m", "d-flex", "flex-column"], ["class", "mb-s mt-0", 4, "ngIf"], [1, "my-0"], [3, "innerHTML"], [1, "ml-auto", "d-flex", "align-items-center", "flex-shrink-0"], ["mat-button", "", "data-cy", "cx-banner-button", 3, "color", "mr-xs", "mr-m", "click", 4, "ngIf"], ["class", "d-flex align-items-center", 4, "ngIf"], [1, "mb-s", "mt-0"], ["mat-button", "", "data-cy", "cx-banner-button", 3, "color", "click"], [1, "d-flex", "align-items-center"], ["vertical", "", 1, "mr-sm", "cx-banner-divider"], ["mat-icon-button", "", "data-cy", "cx-banner-close-button", 1, "cx-banner-close-btn", 3, "click"]],
      template: function CxBannerComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵelementStart(0, "div")(1, "mat-icon", 0);
          i0.ɵɵtext(2);
          i0.ɵɵelementEnd();
          i0.ɵɵelementStart(3, "div", 1);
          i0.ɵɵtemplate(4, CxBannerComponent_h3_4_Template, 2, 1, "h3", 2);
          i0.ɵɵelementStart(5, "p", 3);
          i0.ɵɵtemplate(6, CxBannerComponent_Conditional_6_Template, 1, 1, "span", 4)(7, CxBannerComponent_Conditional_7_Template, 1, 0);
          i0.ɵɵelementEnd()();
          i0.ɵɵelementStart(8, "div", 5);
          i0.ɵɵtemplate(9, CxBannerComponent_button_9_Template, 2, 6, "button", 6)(10, CxBannerComponent_div_10_Template, 5, 0, "div", 7);
          i0.ɵɵelementEnd()();
        }
        if (rf & 2) {
          i0.ɵɵclassMapInterpolate1("cx-banner d-flex align-items-center cx-banner-", ctx.type, "");
          i0.ɵɵclassProp("cx-banner-padding", ctx.title);
          i0.ɵɵadvance(2);
          i0.ɵɵtextInterpolate(ctx.icon);
          i0.ɵɵadvance(2);
          i0.ɵɵproperty("ngIf", ctx.title);
          i0.ɵɵadvance();
          i0.ɵɵclassProp("cx-banner-padding", !ctx.title);
          i0.ɵɵadvance();
          i0.ɵɵconditional(6, ctx.message ? 6 : 7);
          i0.ɵɵadvance(3);
          i0.ɵɵproperty("ngIf", ctx.actionLabel);
          i0.ɵɵadvance();
          i0.ɵɵproperty("ngIf", ctx.dismissable);
        }
      },
      dependencies: [i2.NgIf, i3.MatIcon, i4.MatButton, i4.MatIconButton, i5.MatDivider],
      styles: [".cx-banner[_ngcontent-%COMP%]{background-color:#e3f3ff;border:1px #2da0d3 solid;color:#202020;min-height:60px;font-size:16px;font-weight:400;white-space:pre-line;border-radius:8px}.cx-banner[_ngcontent-%COMP%]   .cx-banner-icon[_ngcontent-%COMP%]{color:#2da0d3}.cx-banner-info[_ngcontent-%COMP%]{background-color:#e3f3ff;border:1px #2da0d3 solid}.cx-banner-info[_ngcontent-%COMP%]   .cx-banner-icon[_ngcontent-%COMP%]{color:#2da0d3}.cx-banner-success[_ngcontent-%COMP%]{background-color:#e0f8f2;border:1px #00a97a solid}.cx-banner-success[_ngcontent-%COMP%]   .cx-banner-icon[_ngcontent-%COMP%]{color:#00a97a}.cx-banner-warning[_ngcontent-%COMP%]{background-color:#f9f6ef;border:1px #fecc00 solid}.cx-banner-warning[_ngcontent-%COMP%]   .cx-banner-icon[_ngcontent-%COMP%]{color:#fecc00}.cx-banner-error[_ngcontent-%COMP%]{background-color:#f6e8e8;border:1px #eb0000 solid}.cx-banner-error[_ngcontent-%COMP%]   .cx-banner-icon[_ngcontent-%COMP%]{color:#eb0000}.cx-banner-alternative[_ngcontent-%COMP%]{background-color:#f3e6f6;border:1px #9e2ab5 solid}.cx-banner-alternative[_ngcontent-%COMP%]   .cx-banner-icon[_ngcontent-%COMP%]{color:#9e2ab5}.cx-banner-divider[_ngcontent-%COMP%]{height:32px}.cx-banner-close-btn[_ngcontent-%COMP%]{margin-right:20px}.cx-banner-padding[_ngcontent-%COMP%]{padding:18px 0}"]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CxBannerComponent, [{
    type: Component,
    args: [{
      selector: 'cx-banner',
      template: "<div class=\"cx-banner d-flex align-items-center cx-banner-{{type}}\" [class.cx-banner-padding]=\"title\">\n  <mat-icon class=\"cx-banner-icon mx-m flex-shrink-0\">{{ icon }}</mat-icon>\n  <div class=\"pr-m d-flex flex-column\">\n    <h3 *ngIf=\"title\" class=\"mb-s mt-0\">{{ title }}</h3>\n    <p class=\"my-0\" [class.cx-banner-padding]=\"!title\">\n      @if (message) {\n      <span [innerHTML]=\"message\"></span>\n      } @else {\n      <ng-content />\n      }\n    </p>\n  </div>\n  <div class=\"ml-auto d-flex align-items-center flex-shrink-0\">\n    <button\n      mat-button\n      *ngIf=\"actionLabel\"\n      [color]=\"type\"\n      data-cy=\"cx-banner-button\"\n      [class.mr-xs]=\"!dismissable\"\n      [class.mr-m]=\"dismissable\"\n      (click)=\"onAction()\"\n    >\n      {{ actionLabel }}\n    </button>\n    <div *ngIf=\"dismissable\" class=\"d-flex align-items-center\">\n      <mat-divider vertical class=\"mr-sm cx-banner-divider\"></mat-divider>\n      <button\n        mat-icon-button\n        class=\"cx-banner-close-btn\"\n        data-cy=\"cx-banner-close-button\"\n        (click)=\"onClose()\"\n      >\n        <mat-icon>close</mat-icon>\n      </button>\n    </div>\n  </div>\n</div>\n",
      styles: [".cx-banner{background-color:#e3f3ff;border:1px #2da0d3 solid;color:#202020;min-height:60px;font-size:16px;font-weight:400;white-space:pre-line;border-radius:8px}.cx-banner .cx-banner-icon{color:#2da0d3}.cx-banner-info{background-color:#e3f3ff;border:1px #2da0d3 solid}.cx-banner-info .cx-banner-icon{color:#2da0d3}.cx-banner-success{background-color:#e0f8f2;border:1px #00a97a solid}.cx-banner-success .cx-banner-icon{color:#00a97a}.cx-banner-warning{background-color:#f9f6ef;border:1px #fecc00 solid}.cx-banner-warning .cx-banner-icon{color:#fecc00}.cx-banner-error{background-color:#f6e8e8;border:1px #eb0000 solid}.cx-banner-error .cx-banner-icon{color:#eb0000}.cx-banner-alternative{background-color:#f3e6f6;border:1px #9e2ab5 solid}.cx-banner-alternative .cx-banner-icon{color:#9e2ab5}.cx-banner-divider{height:32px}.cx-banner-close-btn{margin-right:20px}.cx-banner-padding{padding:18px 0}\n"]
    }]
  }], () => [{
    type: i1.DomSanitizer
  }], {
    message: [{
      type: Input
    }],
    title: [{
      type: Input
    }],
    actionLabel: [{
      type: Input
    }],
    type: [{
      type: Input
    }],
    icon: [{
      type: Input
    }],
    dismissable: [{
      type: Input
    }],
    actionEvent: [{
      type: Output
    }],
    closeEvent: [{
      type: Output
    }]
  });
})();
const components = [CxBannerComponent];
class CxBannerModule {
  static {
    this.ɵfac = function CxBannerModule_Factory(t) {
      return new (t || CxBannerModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: CxBannerModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      imports: [CommonModule, MatIconModule, MatButtonModule, MatDividerModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CxBannerModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule, MatIconModule, MatButtonModule, MatDividerModule],
      declarations: [...components],
      exports: [...components]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { CxBannerComponent, CxBannerModule };
