<mat-tab-group (selectedTabChange)="navigateOnTab($event)" [selectedIndex]="selectedIndex">
  <mat-tab>
    <ng-template mat-tab-label>
      <mat-icon class="material-icons-outlined">folder_copy</mat-icon>
      {{ 'ORDER_OVERVIEW.TAB' | translate }}
    </ng-template>
  </mat-tab>
  @if(hasAnyOfThoseRoles(currentUser, [userRole.ADMIN, userRole.OFFICE_SERVICE])) {
  <mat-tab>
    <ng-template mat-tab-label>
      <mat-icon class="material-symbols-outlined">edit_document</mat-icon>
      <span>{{ 'TEMPLATE_OVERVIEW.TAB' | translate }}</span>
    </ng-template>
  </mat-tab>
  }
  @if (hasAnyOfThoseRoles(currentUser, [userRole.ADMIN])) {
    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon class="material-symbols-outlined">settings</mat-icon>
        <span>{{ 'ADMIN.TAB' | translate }}</span>
      </ng-template>
    </mat-tab>
  }
</mat-tab-group>
