import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SecureDownloadDirective } from './util/secure-download.directive';
import { NotificationComponent } from './header/notification/notification.component';
import { UIModule } from '../ui.module';
import { TranslateModule } from '@ngx-translate/core';
import { HeaderComponent } from './header/header.component';
import { LoginModule } from '../login/login.module';

@NgModule({
  declarations: [
    SecureDownloadDirective,
    NotificationComponent,
    HeaderComponent,
  ],
  imports: [CommonModule, UIModule, TranslateModule, LoginModule],
  exports: [SecureDownloadDirective, HeaderComponent], // Export the directive so it can be used in other modules
})
export class SharedModule {}
