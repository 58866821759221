<div class="profile-icons">
  <cx-profile-dropdown
    *ngIf="currentUser"
    [email]="currentUser.email"
    [firstname]="currentUser.firstname"
    [lastname]="currentUser.lastname"
    (logout)="logout()"
    (goToProfile)="navigateToGPP()"
  >
  </cx-profile-dropdown>
</div>
