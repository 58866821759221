import * as i0 from '@angular/core';
import { Component, Input, NgModule } from '@angular/core';
import * as i1 from '@angular/common';
import { CommonModule } from '@angular/common';
import * as i2 from '@angular/material/button';
import { MatButtonModule } from '@angular/material/button';
import { MatRippleModule } from '@angular/material/core';
import * as i1$1 from '@angular/material/icon';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
function CxHeaderComponent_div_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "div", 9);
  }
}
const _c0 = [[["cx-header-back-navigator"]], [["cx-header-title"]], [["cx-search-bar"]], [["cx-header-quickbar"]]];
const _c1 = ["cx-header-back-navigator", "cx-header-title", "cx-search-bar", "cx-header-quickbar"];
const _c2 = ["*", [["cx-profile-dropdown"]]];
const _c3 = ["*", "cx-profile-dropdown"];
const _c4 = ["*"];
class CxHeaderComponent {
  constructor() {
    this.navigationDrawerMode = false;
    this.layoutClass = 'container';
  }
  static {
    this.ɵfac = function CxHeaderComponent_Factory(t) {
      return new (t || CxHeaderComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: CxHeaderComponent,
      selectors: [["cx-header"]],
      inputs: {
        navigationDrawerMode: "navigationDrawerMode",
        layoutClass: "layoutClass"
      },
      ngContentSelectors: _c1,
      decls: 16,
      vars: 9,
      consts: [[1, "cx-header", "d-flex", "align-items-center"], ["class", "cx-header-navi-drawer-width h-100 flex-shrink-0", 4, "ngIf"], [1, "d-flex", "align-items-center", "h-100"], ["backNavRef", ""], [1, "flex-shrink-0"], ["titleRef", ""], [1, "cx-header-search-bar"], ["searchBarRef", ""], ["quickbarRef", ""], [1, "cx-header-navi-drawer-width", "h-100", "flex-shrink-0"]],
      template: function CxHeaderComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef(_c0);
          i0.ɵɵelementStart(0, "div", 0);
          i0.ɵɵtemplate(1, CxHeaderComponent_div_1_Template, 1, 0, "div", 1);
          i0.ɵɵelementStart(2, "div")(3, "div", 2)(4, "span", null, 3);
          i0.ɵɵprojection(6);
          i0.ɵɵelementEnd();
          i0.ɵɵelementStart(7, "span", 4, 5);
          i0.ɵɵprojection(9, 1);
          i0.ɵɵelementEnd();
          i0.ɵɵelementStart(10, "div", 6, 7);
          i0.ɵɵprojection(12, 2);
          i0.ɵɵelementEnd();
          i0.ɵɵelementStart(13, "span", 4, 8);
          i0.ɵɵprojection(15, 3);
          i0.ɵɵelementEnd()()()();
        }
        if (rf & 2) {
          const _r1 = i0.ɵɵreference(5);
          const _r2 = i0.ɵɵreference(8);
          const _r4 = i0.ɵɵreference(14);
          i0.ɵɵadvance();
          i0.ɵɵproperty("ngIf", ctx.navigationDrawerMode);
          i0.ɵɵadvance();
          i0.ɵɵclassMap("w-100 h-100 " + (ctx.navigationDrawerMode && ctx.layoutClass === "container" ? "nd-container" : ctx.layoutClass));
          i0.ɵɵadvance(5);
          i0.ɵɵclassProp("ml-ml", _r1.children.length);
          i0.ɵɵadvance(3);
          i0.ɵɵclassProp("ml-ml", _r2.children.length || _r1.children.length)("mr-ml", _r4.children.length);
        }
      },
      dependencies: [i1.NgIf],
      styles: [".cx-header[_ngcontent-%COMP%]{background-color:#00a97a;color:#fff;position:sticky;width:100%;z-index:1000;top:0;height:76px}.cx-header-search-bar[_ngcontent-%COMP%]{flex-grow:1}.cx-header-navi-drawer-width[_ngcontent-%COMP%]{width:256px}"]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CxHeaderComponent, [{
    type: Component,
    args: [{
      selector: 'cx-header',
      template: "<div class=\"cx-header d-flex align-items-center\">\n  <div\n    *ngIf=\"navigationDrawerMode\"\n    class=\"cx-header-navi-drawer-width h-100 flex-shrink-0\"\n  ></div>\n  <div\n    [class]=\"\n      'w-100 h-100 ' +\n      ((navigationDrawerMode && layoutClass === 'container' ) ? 'nd-container' : layoutClass)\n    \"\n  >\n    <div class=\"d-flex align-items-center h-100\">\n      <span #backNavRef>\n        <ng-content select=\"cx-header-back-navigator\"></ng-content>\n      </span>\n      <span #titleRef class=\"flex-shrink-0\" [class.ml-ml]=\"backNavRef.children.length\">\n        <ng-content select=\"cx-header-title\"></ng-content>\n      </span>\n      <div\n        #searchBarRef\n        class=\"cx-header-search-bar\"\n        [class.ml-ml]=\"titleRef.children.length || backNavRef.children.length\"\n        [class.mr-ml]=\"quickbarRef.children.length\"\n      >\n        <ng-content select=\"cx-search-bar\"></ng-content>\n      </div>\n      <span #quickbarRef class=\"flex-shrink-0\">\n        <ng-content select=\"cx-header-quickbar\"></ng-content>\n      </span>\n    </div>\n  </div>\n</div>\n",
      styles: [".cx-header{background-color:#00a97a;color:#fff;position:sticky;width:100%;z-index:1000;top:0;height:76px}.cx-header-search-bar{flex-grow:1}.cx-header-navi-drawer-width{width:256px}\n"]
    }]
  }], null, {
    navigationDrawerMode: [{
      type: Input
    }],
    layoutClass: [{
      type: Input
    }]
  });
})();
class CxHeaderBackNavigatorComponent {
  static {
    this.ɵfac = function CxHeaderBackNavigatorComponent_Factory(t) {
      return new (t || CxHeaderBackNavigatorComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: CxHeaderBackNavigatorComponent,
      selectors: [["cx-header-back-navigator"]],
      decls: 3,
      vars: 0,
      consts: [["mat-icon-button", "", "data-cy", "cx-header-back", 1, "cx-header-white-icon"], [1, "white-medium-icon"]],
      template: function CxHeaderBackNavigatorComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelementStart(0, "button", 0)(1, "mat-icon", 1);
          i0.ɵɵtext(2, "arrow_back");
          i0.ɵɵelementEnd()();
        }
      },
      dependencies: [i1$1.MatIcon, i2.MatIconButton]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CxHeaderBackNavigatorComponent, [{
    type: Component,
    args: [{
      selector: 'cx-header-back-navigator',
      template: "<button mat-icon-button class=\"cx-header-white-icon\" data-cy=\"cx-header-back\">\n  <mat-icon class=\"white-medium-icon\">arrow_back</mat-icon>\n</button>\n"
    }]
  }], null, null);
})();
class CxHeaderQuickbarComponent {
  static {
    this.ɵfac = function CxHeaderQuickbarComponent_Factory(t) {
      return new (t || CxHeaderQuickbarComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: CxHeaderQuickbarComponent,
      selectors: [["cx-header-quickbar"]],
      ngContentSelectors: _c3,
      decls: 2,
      vars: 0,
      template: function CxHeaderQuickbarComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef(_c2);
          i0.ɵɵprojection(0);
          i0.ɵɵprojection(1, 1);
        }
      },
      styles: ["[_nghost-%COMP%]{display:flex}"]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CxHeaderQuickbarComponent, [{
    type: Component,
    args: [{
      selector: 'cx-header-quickbar',
      template: "<ng-content></ng-content>\n<ng-content select=\"cx-profile-dropdown\"></ng-content>\n\n",
      styles: [":host{display:flex}\n"]
    }]
  }], null, null);
})();
class CxHeaderTitleComponent {
  static {
    this.ɵfac = function CxHeaderTitleComponent_Factory(t) {
      return new (t || CxHeaderTitleComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: CxHeaderTitleComponent,
      selectors: [["cx-header-title"]],
      ngContentSelectors: _c4,
      decls: 2,
      vars: 0,
      template: function CxHeaderTitleComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵelementStart(0, "h1");
          i0.ɵɵprojection(1);
          i0.ɵɵelementEnd();
        }
      }
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CxHeaderTitleComponent, [{
    type: Component,
    args: [{
      selector: 'cx-header-title',
      template: "<h1>\n  <ng-content></ng-content>\n</h1>\n"
    }]
  }], null, null);
})();
const components = [CxHeaderComponent, CxHeaderTitleComponent, CxHeaderBackNavigatorComponent, CxHeaderQuickbarComponent];
class CxHeaderModule {
  static {
    this.ɵfac = function CxHeaderModule_Factory(t) {
      return new (t || CxHeaderModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: CxHeaderModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      imports: [CommonModule, MatIconModule, MatMenuModule, MatRippleModule, MatButtonModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CxHeaderModule, [{
    type: NgModule,
    args: [{
      declarations: [...components],
      imports: [CommonModule, MatIconModule, MatMenuModule, MatRippleModule, MatButtonModule],
      exports: [...components]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { CxHeaderBackNavigatorComponent, CxHeaderComponent, CxHeaderModule, CxHeaderQuickbarComponent, CxHeaderTitleComponent };
