import { NgModule } from '@angular/core';
import { CxHeaderModule } from '@bbraun/cortex/header';
import { CxFloatingButtonModule } from '@bbraun/cortex/floating-button';
import { CxTableUtilitiesModule } from '@bbraun/cortex/table-utilities';
import { CxTableWithPaginationModule } from '@bbraun/cortex/table-with-pagination';
import { MatTabsModule } from '@angular/material/tabs';
import { CxNumberInputModule } from '@bbraun/cortex/number-input';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import {
  CxHeartBeatModule,
  CxImageModule,
  CxMenuModule,
} from '@bbraun/cortex/carousel';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { CxProfileDropdownComponent } from '@bbraun/cortex/profile-dropdown';
import { CxLogoModule } from '@bbraun/cortex/logo';
import { CxSnackbarModule } from '@bbraun/cortex/snackbar';
import { CxFileUploaderModule } from '@bbraun/cortex/file-uploader';
import { MatDividerModule } from '@angular/material/divider';
import { CxSearchBarModule } from '@bbraun/cortex/search-bar';
import { CxCardModule } from '@bbraun/cortex/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { CdkConnectedOverlay, CdkOverlayOrigin } from '@angular/cdk/overlay';
import { CxListModule } from '@bbraun/cortex/list';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatStepperModule } from '@angular/material/stepper';
import { CxExpansionPanelComponent } from '@bbraun/cortex/expansion-panel';
import { MatNativeDateModule } from '@angular/material/core';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { CxDialogModule } from '@bbraun/cortex/dialog';
import { NgxColorsModule } from 'ngx-colors';
import { CxBannerModule } from '@bbraun/cortex/banner';
import { CxSelectableListModule } from '@bbraun/cortex/selectable-list';
import { CxMessageModule } from '@bbraun/cortex/message';

@NgModule({
  declarations: [],
  imports: [
    CxProfileDropdownComponent,
    CdkConnectedOverlay,
    CdkOverlayOrigin,
    CxExpansionPanelComponent,
  ],
  exports: [
    CdkConnectedOverlay,
    CdkOverlayOrigin,
    CxBannerModule,
    CxCardModule,
    CxDialogModule,
    CxExpansionPanelComponent,
    CxFileUploaderModule,
    CxFloatingButtonModule,
    CxHeaderModule,
    CxHeartBeatModule,
    CxImageModule,
    CxListModule,
    CxLogoModule,
    CxMenuModule,
    CxMessageModule,
    CxNumberInputModule,
    CxProfileDropdownComponent,
    CxSearchBarModule,
    CxSnackbarModule,
    CxTableUtilitiesModule,
    CxTableWithPaginationModule,
    DragDropModule,
    MatAutocompleteModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatDividerModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatInputModule,
    MatNativeDateModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatStepperModule,
    MatTableModule,
    MatTabsModule,
    NgxColorsModule,
    CxSelectableListModule,
  ],
})
export class UIModule {}
