import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

export const PERMISSION_DENIED = 'permission_denied';

@Component({
  selector: 'hpm-error-page',
  templateUrl: './error-page.component.html',
  styleUrl: './error-page.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ErrorPageComponent implements OnInit {
  headline = '';
  description = '';
  buttonText = '';

  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.route.paramMap.subscribe((params) => {
      const currentId = params.get('id');

      if (currentId === PERMISSION_DENIED) {
        this.headline = 'ERROR_PAGE.NOT_ALLOWED.HEADLINE';
        this.description = 'ERROR_PAGE.NOT_ALLOWED.DESCRIPTION';
      } else {
        this.headline = 'ERROR_PAGE.NOT_FOUND.HEADLINE';
        this.description = 'ERROR_PAGE.NOT_FOUND.DESCRIPTION';
        this.buttonText = 'ERROR_PAGE.NOT_FOUND.BUTTON';
      }
    });
  }
}
