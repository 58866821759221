import { coerceNumberProperty, coerceBooleanProperty } from '@angular/cdk/coercion';
import * as i0 from '@angular/core';
import { EventEmitter, Component, ChangeDetectionStrategy, Optional, Self, HostBinding, Input, Output, ViewChild, NgModule } from '@angular/core';
import * as i1 from '@angular/forms';
import { FormsModule } from '@angular/forms';
import '@angular/material/core';
import * as i2 from '@angular/material/form-field';
import { MatFormFieldControl, MatFormFieldModule } from '@angular/material/form-field';
import { DEBOUNCE_TIME } from '@bbraun/cortex/shared';
export * from '@bbraun/cortex/shared';
import { Subject } from 'rxjs';
import { tap, filter, map, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import * as i3 from '@angular/material/input';
import { MatInputModule } from '@angular/material/input';
import * as i4 from '@angular/material/button';
import { MatButtonModule } from '@angular/material/button';
import * as i5 from '@angular/material/icon';
import { MatIconModule } from '@angular/material/icon';
import * as i6 from '@angular/common';
import { CommonModule } from '@angular/common';

/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable no-underscore-dangle */
const _c0 = ["input"];
function CxNumberInputComponent_button_0_Template(rf, ctx) {
  if (rf & 1) {
    const _r4 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "button", 4);
    i0.ɵɵlistener("mouseenter", function CxNumberInputComponent_button_0_Template_button_mouseenter_0_listener() {
      i0.ɵɵrestoreView(_r4);
      const ctx_r3 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r3.mouseEvent$.next(true));
    })("mouseleave", function CxNumberInputComponent_button_0_Template_button_mouseleave_0_listener() {
      i0.ɵɵrestoreView(_r4);
      const ctx_r5 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r5.mouseEvent$.next(false));
    })("click", function CxNumberInputComponent_button_0_Template_button_click_0_listener() {
      i0.ɵɵrestoreView(_r4);
      const ctx_r6 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r6.onSideButton(-ctx_r6.step));
    });
    i0.ɵɵelementStart(1, "mat-icon");
    i0.ɵɵtext(2, "remove");
    i0.ɵɵelementEnd()();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵproperty("disabled", ctx_r0.min !== null && ctx_r0.value !== null && ctx_r0.value <= ctx_r0.min || ctx_r0.disabled);
  }
}
function CxNumberInputComponent_button_3_Template(rf, ctx) {
  if (rf & 1) {
    const _r8 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "button", 4);
    i0.ɵɵlistener("mouseenter", function CxNumberInputComponent_button_3_Template_button_mouseenter_0_listener() {
      i0.ɵɵrestoreView(_r8);
      const ctx_r7 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r7.mouseEvent$.next(true));
    })("mouseleave", function CxNumberInputComponent_button_3_Template_button_mouseleave_0_listener() {
      i0.ɵɵrestoreView(_r8);
      const ctx_r9 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r9.mouseEvent$.next(false));
    })("click", function CxNumberInputComponent_button_3_Template_button_click_0_listener() {
      i0.ɵɵrestoreView(_r8);
      const ctx_r10 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r10.onSideButton(-ctx_r10.step));
    });
    i0.ɵɵelementStart(1, "mat-icon");
    i0.ɵɵtext(2, "remove");
    i0.ɵɵelementEnd()();
  }
  if (rf & 2) {
    const ctx_r2 = i0.ɵɵnextContext();
    i0.ɵɵproperty("disabled", ctx_r2.min !== null && ctx_r2.value !== null && ctx_r2.value <= ctx_r2.min || ctx_r2.disabled);
  }
}
class CxNumberInputComponent {
  static {
    this.nextId = 0;
  }
  get empty() {
    return this.value === null;
  }
  get nativeElement() {
    return this.inputRef.nativeElement;
  }
  get shouldLabelFloat() {
    return this.focused || !this.empty;
  }
  /**
   * Amount of time that debouncedChange will be debounced
   * Only init value will be used
   *
   * @see {@link debouncedChange} event
   */
  get debounceTime() {
    return this._debounceTime;
  }
  set debounceTime(val) {
    this._debounceTime = val !== null ? coerceNumberProperty(val) : this.debounceTime;
  }
  get placeholder() {
    return this._placeholder;
  }
  set placeholder(plh) {
    this._placeholder = plh;
    this.stateChanges.next();
  }
  get required() {
    return this._required;
  }
  set required(req) {
    this._required = coerceBooleanProperty(req);
    this.stateChanges.next();
  }
  get disabled() {
    return this._disabled;
  }
  set disabled(value) {
    this._disabled = coerceBooleanProperty(value);
    this.stateChanges.next();
  }
  get readonly() {
    return this._readonly;
  }
  set readonly(value) {
    this._readonly = coerceBooleanProperty(value);
    this.stateChanges.next();
  }
  get step() {
    return this._step;
  }
  set step(stp) {
    this._step = coerceNumberProperty(stp);
    this.stateChanges.next();
  }
  get max() {
    return this._max;
  }
  set max(mx) {
    this._max = coerceNumberProperty(mx);
    this.stateChanges.next();
  }
  get min() {
    return this._min;
  }
  set min(mn) {
    this._min = coerceNumberProperty(mn);
    this.stateChanges.next();
  }
  get maxLength() {
    return this._maxLength;
  }
  set maxLength(mx) {
    this._maxLength = coerceNumberProperty(mx);
    this.stateChanges.next();
  }
  get blockedChars() {
    return this._blockedChars;
  }
  set blockedChars(value) {
    this._blockedChars += value;
    this.stateChanges.next();
  }
  get errorStateMatcher() {
    return this._errorStateMatcher;
  }
  set errorStateMatcher(matcher) {
    this._errorStateMatcher = matcher;
    this.stateChanges.next();
  }
  get value() {
    return this._value;
  }
  set value(val) {
    this._value = val !== null ? coerceNumberProperty(val) : null;
    this.dirty = true;
    if (val === null) {
      this.touched = false;
    } else {
      this.onTouched();
    }
    this.onChange(this.value);
    this.stateChanges.next();
  }
  get errorState() {
    return this.errorStateMatcher?.isErrorState(this.ngControl?.control, this.formGroup) || this.touched && this.ngControl?.invalid;
  }
  constructor(renderer, _elementRef, changeDetectorRef, formGroup, ngControl, _formField) {
    this.renderer = renderer;
    this._elementRef = _elementRef;
    this.changeDetectorRef = changeDetectorRef;
    this.formGroup = formGroup;
    this.ngControl = ngControl;
    this._formField = _formField;
    this.id = `cx-number-input-${CxNumberInputComponent.nextId++}`;
    /**
     * Event that fires only unique and debounced results of input value change
     *
     * @see {@link debounceTime} property
     */
    this.debouncedChange = new EventEmitter();
    this.stateChanges = new Subject();
    this.mouseEvent$ = new Subject();
    this.focused = false;
    this.touched = false;
    this.dirty = false;
    this.controlType = 'cx-number-input';
    /**
     * If set to true, when navigating through the form with the Tab key, the
     * first selected element will be the '-' button. Default behavior is to
     * select the input first
     */
    this.tabIndexStart = false;
    this._debounceTime = DEBOUNCE_TIME;
    this.initValue = null;
    this.autocomplete = null;
    this._placeholder = '';
    this._required = false;
    this._disabled = false;
    this._readonly = false;
    this._step = 1;
    this._max = null;
    this._min = null;
    this._maxLength = null;
    this._blockedChars = 'eE';
    this.onChange = () => {};
    this.onTouched = () => {};
    if (this.ngControl !== null) {
      this.ngControl.valueAccessor = this;
    }
    if (this._formField !== null) {
      const formFieldNative = this._formField._elementRef.nativeElement;
      this.renderer.addClass(formFieldNative, 'cx-number-input-form-field');
      this.mouseEvent$.subscribe(isMouseOverButton => {
        this.renderer[isMouseOverButton && !this.errorState ? 'addClass' : 'removeClass'](formFieldNative, 'cx-disable-hover-outline');
      });
    }
  }
  onSideButton(changeValueBy) {
    let newVal = (this.value ?? this.min ?? 0 ?? this.initValue) + changeValueBy;
    if (this.min !== null || this.max !== null) {
      if (this.value === null) {
        if (this.min !== null && this.initValue === null) {
          newVal = this.min;
        } else if (this.initValue !== null) {
          newVal = this.initValue;
        } else if (this.max !== null) {
          newVal = this.max;
        }
      } else {
        if (this.min !== null && newVal < this.min) {
          newVal = this.min;
        } else if (this.max !== null && newVal > this.max) {
          newVal = this.max;
        }
      }
    }
    this.markAsTouched();
    this.value = newVal;
  }
  ngOnInit() {
    this.stateChanges.pipe(tap(() => {
      this.changeDetectorRef.detectChanges();
    }), filter(() => this.dirty), map(() => this.value), debounceTime(this._debounceTime), distinctUntilChanged()).subscribe(value => {
      this.debouncedChange.emit(value);
    });
  }
  ngOnDestroy() {
    this.stateChanges.complete();
    this.mouseEvent$.complete();
  }
  onFocusIn() {
    if (!this.focused) {
      this.focused = true;
      this.stateChanges.next();
    }
  }
  onFocusOut() {
    this.touched = true;
    this.focused = false;
    this.onTouched();
    this.stateChanges.next();
  }
  setDescribedByIds(ids) {
    const controlElement = this._elementRef.nativeElement.querySelector('.cx-number-input');
    controlElement.setAttribute('aria-describedby', ids.join(' '));
  }
  onContainerClick(event) {
    const className = event.target.className.toLowerCase();
    if (className !== 'mat-mdc-button-touch-target') {
      this._elementRef.nativeElement.querySelector('input').focus();
    }
  }
  writeValue(val) {
    this.value = val;
  }
  registerOnChange(fn) {
    this.onChange = fn;
  }
  registerOnTouched(fn) {
    this.onTouched = fn;
  }
  setDisabledState(isDisabled) {
    this.disabled = isDisabled;
  }
  preventFromTypingCharacters(event) {
    return !this.blockedChars.includes(event.key);
  }
  preventFromExceedingMaxLength(event) {
    if (this.maxLength && this.value) {
      const valueLength = ('' + this.value).length;
      const allowedKeys = ['ArrowLeft', 'ArrowRight', 'Backspace'];
      return valueLength < this.maxLength || allowedKeys.includes(event.key);
    }
    return true;
  }
  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }
  static {
    this.ɵfac = function CxNumberInputComponent_Factory(t) {
      return new (t || CxNumberInputComponent)(i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i1.FormGroupDirective, 8), i0.ɵɵdirectiveInject(i1.NgControl, 10), i0.ɵɵdirectiveInject(i2.MatFormField, 8));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: CxNumberInputComponent,
      selectors: [["cx-number-input"]],
      viewQuery: function CxNumberInputComponent_Query(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵviewQuery(_c0, 5);
        }
        if (rf & 2) {
          let _t;
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.inputRef = _t.first);
        }
      },
      hostVars: 5,
      hostBindings: function CxNumberInputComponent_HostBindings(rf, ctx) {
        if (rf & 2) {
          i0.ɵɵhostProperty("id", ctx.id);
          i0.ɵɵclassProp("floating", ctx.shouldLabelFloat)("mat-form-field-disabled", ctx.disabled);
        }
      },
      inputs: {
        userAriaDescribedBy: [i0.ɵɵInputFlags.None, "aria-describedby", "userAriaDescribedBy"],
        tabIndexStart: "tabIndexStart",
        debounceTime: "debounceTime",
        initValue: "initValue",
        autocomplete: "autocomplete",
        placeholder: "placeholder",
        required: "required",
        disabled: "disabled",
        readonly: "readonly",
        step: "step",
        max: "max",
        min: "min",
        maxLength: "maxLength",
        blockedChars: "blockedChars",
        errorStateMatcher: "errorStateMatcher",
        value: "value"
      },
      outputs: {
        debouncedChange: "debouncedChange"
      },
      features: [i0.ɵɵProvidersFeature([{
        provide: MatFormFieldControl,
        useExisting: CxNumberInputComponent
      }])],
      decls: 7,
      vars: 12,
      consts: [["type", "button", "mat-stroked-button", "", "class", "cx-number-input-button cx-number-input-minus-button cx-icon-stroked-button", 3, "disabled", "mouseenter", "mouseleave", "click", 4, "ngIf"], ["matInput", "", "type", "number", 1, "cx-number-input", "mat-input-element", 3, "step", "max", "min", "readonly", "disabled", "autocomplete", "errorStateMatcher", "ngModel", "focusin", "focusout", "keydown", "ngModelChange"], ["input", ""], ["type", "button", "mat-stroked-button", "", 1, "cx-number-input-button", "cx-number-input-plus-button", "cx-icon-stroked-button", 3, "disabled", "mouseenter", "mouseleave", "click"], ["type", "button", "mat-stroked-button", "", 1, "cx-number-input-button", "cx-number-input-minus-button", "cx-icon-stroked-button", 3, "disabled", "mouseenter", "mouseleave", "click"]],
      template: function CxNumberInputComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵtemplate(0, CxNumberInputComponent_button_0_Template, 3, 1, "button", 0);
          i0.ɵɵelementStart(1, "input", 1, 2);
          i0.ɵɵlistener("focusin", function CxNumberInputComponent_Template_input_focusin_1_listener() {
            return ctx.onFocusIn();
          })("focusout", function CxNumberInputComponent_Template_input_focusout_1_listener() {
            return ctx.onFocusOut();
          })("keydown", function CxNumberInputComponent_Template_input_keydown_1_listener($event) {
            return ctx.preventFromExceedingMaxLength($event) && ctx.preventFromTypingCharacters($event);
          });
          i0.ɵɵtwoWayListener("ngModelChange", function CxNumberInputComponent_Template_input_ngModelChange_1_listener($event) {
            i0.ɵɵtwoWayBindingSet(ctx.value, $event) || (ctx.value = $event);
            return $event;
          });
          i0.ɵɵelementEnd();
          i0.ɵɵtemplate(3, CxNumberInputComponent_button_3_Template, 3, 1, "button", 0);
          i0.ɵɵelementStart(4, "button", 3);
          i0.ɵɵlistener("mouseenter", function CxNumberInputComponent_Template_button_mouseenter_4_listener() {
            return ctx.mouseEvent$.next(true);
          })("mouseleave", function CxNumberInputComponent_Template_button_mouseleave_4_listener() {
            return ctx.mouseEvent$.next(false);
          })("click", function CxNumberInputComponent_Template_button_click_4_listener() {
            return ctx.onSideButton(ctx.step);
          });
          i0.ɵɵelementStart(5, "mat-icon");
          i0.ɵɵtext(6, "add");
          i0.ɵɵelementEnd()();
        }
        if (rf & 2) {
          i0.ɵɵproperty("ngIf", ctx.tabIndexStart);
          i0.ɵɵadvance();
          i0.ɵɵproperty("step", ctx.step)("max", ctx.max)("min", ctx.min)("readonly", ctx.readonly)("disabled", ctx.disabled)("autocomplete", ctx.autocomplete)("errorStateMatcher", ctx.errorStateMatcher);
          i0.ɵɵtwoWayProperty("ngModel", ctx.value);
          i0.ɵɵattribute("aria-labelledby", ctx._formField == null ? null : ctx._formField.getLabelId());
          i0.ɵɵadvance(2);
          i0.ɵɵproperty("ngIf", !ctx.tabIndexStart);
          i0.ɵɵadvance();
          i0.ɵɵproperty("disabled", ctx.max !== null && ctx.value !== null && ctx.value >= ctx.max || ctx.disabled);
        }
      },
      dependencies: [i1.DefaultValueAccessor, i1.NumberValueAccessor, i1.NgControlStatus, i1.MinValidator, i1.MaxValidator, i1.NgModel, i3.MatInput, i4.MatButton, i5.MatIcon, i6.NgIf],
      styles: ["[_nghost-%COMP%]{position:relative;display:block}.cx-number-input-button[_ngcontent-%COMP%]{display:flex;justify-content:center;align-items:center;position:absolute;top:-10px}.cx-number-input-button[_ngcontent-%COMP%]   .mat-icon[_ngcontent-%COMP%]{font-size:18px!important;height:18px!important;width:18px!important}input[type=number][_ngcontent-%COMP%]{-moz-appearance:textfield;text-align:center}input[type=number][_ngcontent-%COMP%]::-webkit-outer-spin-button, input[type=number][_ngcontent-%COMP%]::-webkit-inner-spin-button{-webkit-appearance:none}.cx-number-input-plus-button[_ngcontent-%COMP%]{right:-64px}.cx-number-input-minus-button[_ngcontent-%COMP%]{left:-64px}"],
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CxNumberInputComponent, [{
    type: Component,
    args: [{
      selector: 'cx-number-input',
      providers: [{
        provide: MatFormFieldControl,
        useExisting: CxNumberInputComponent
      }],
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: "<button\n  *ngIf=\"this.tabIndexStart\"\n  type=\"button\"\n  mat-stroked-button\n  class=\"cx-number-input-button cx-number-input-minus-button cx-icon-stroked-button\"\n  [disabled]=\"(min !== null && value !== null && value <= min) || disabled\"\n  (mouseenter)=\"mouseEvent$.next(true)\"\n  (mouseleave)=\"mouseEvent$.next(false)\"\n  (click)=\"onSideButton(-step)\"\n>\n  <mat-icon>remove</mat-icon>\n</button>\n\n<input\n  #input\n  matInput\n  class=\"cx-number-input mat-input-element\"\n  type=\"number\"\n  [step]=\"step\"\n  [attr.aria-labelledby]=\"_formField?.getLabelId()\"\n  [max]=\"max\"\n  [min]=\"min\"\n  [readonly]=\"readonly\"\n  [disabled]=\"disabled\"\n  [autocomplete]=\"autocomplete\"\n  [errorStateMatcher]=\"errorStateMatcher\"\n  (focusin)=\"onFocusIn()\"\n  (focusout)=\"onFocusOut()\"\n  (keydown)=\"\n    preventFromExceedingMaxLength($event) && preventFromTypingCharacters($event)\n  \"\n  [(ngModel)]=\"value\"\n/>\n\n<button\n  *ngIf=\"!this.tabIndexStart\"\n  type=\"button\"\n  mat-stroked-button\n  class=\"cx-number-input-button cx-number-input-minus-button cx-icon-stroked-button\"\n  [disabled]=\"(min !== null && value !== null && value <= min) || disabled\"\n  (mouseenter)=\"mouseEvent$.next(true)\"\n  (mouseleave)=\"mouseEvent$.next(false)\"\n  (click)=\"onSideButton(-step)\"\n>\n  <mat-icon>remove</mat-icon>\n</button>\n\n<button\n  type=\"button\"\n  mat-stroked-button\n  class=\"cx-number-input-button cx-number-input-plus-button cx-icon-stroked-button\"\n  [disabled]=\"(max !== null && value !== null && value >= max) || disabled\"\n  (mouseenter)=\"mouseEvent$.next(true)\"\n  (mouseleave)=\"mouseEvent$.next(false)\"\n  (click)=\"onSideButton(step)\"\n>\n  <mat-icon>add</mat-icon>\n</button>\n",
      styles: [":host{position:relative;display:block}.cx-number-input-button{display:flex;justify-content:center;align-items:center;position:absolute;top:-10px}.cx-number-input-button .mat-icon{font-size:18px!important;height:18px!important;width:18px!important}input[type=number]{-moz-appearance:textfield;text-align:center}input[type=number]::-webkit-outer-spin-button,input[type=number]::-webkit-inner-spin-button{-webkit-appearance:none}.cx-number-input-plus-button{right:-64px}.cx-number-input-minus-button{left:-64px}\n"]
    }]
  }], () => [{
    type: i0.Renderer2
  }, {
    type: i0.ElementRef
  }, {
    type: i0.ChangeDetectorRef
  }, {
    type: i1.FormGroupDirective,
    decorators: [{
      type: Optional
    }]
  }, {
    type: i1.NgControl,
    decorators: [{
      type: Optional
    }, {
      type: Self
    }]
  }, {
    type: i2.MatFormField,
    decorators: [{
      type: Optional
    }]
  }], {
    id: [{
      type: HostBinding
    }],
    userAriaDescribedBy: [{
      type: Input,
      args: ['aria-describedby']
    }],
    debouncedChange: [{
      type: Output
    }],
    inputRef: [{
      type: ViewChild,
      args: ['input']
    }],
    shouldLabelFloat: [{
      type: HostBinding,
      args: ['class.floating']
    }],
    tabIndexStart: [{
      type: Input
    }],
    debounceTime: [{
      type: Input
    }],
    initValue: [{
      type: Input
    }],
    autocomplete: [{
      type: Input
    }],
    placeholder: [{
      type: Input
    }],
    required: [{
      type: Input
    }],
    disabled: [{
      type: HostBinding,
      args: ['class.mat-form-field-disabled']
    }, {
      type: Input
    }],
    readonly: [{
      type: Input
    }],
    step: [{
      type: Input
    }],
    max: [{
      type: Input
    }],
    min: [{
      type: Input
    }],
    maxLength: [{
      type: Input
    }],
    blockedChars: [{
      type: Input
    }],
    errorStateMatcher: [{
      type: Input
    }],
    value: [{
      type: Input
    }]
  });
})();
const components = [CxNumberInputComponent];
class CxNumberInputModule {
  static {
    this.ɵfac = function CxNumberInputModule_Factory(t) {
      return new (t || CxNumberInputModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: CxNumberInputModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      imports: [FormsModule, MatFormFieldModule, MatInputModule, MatButtonModule, MatIconModule, CommonModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CxNumberInputModule, [{
    type: NgModule,
    args: [{
      declarations: [...components],
      imports: [FormsModule, MatFormFieldModule, MatInputModule, MatButtonModule, MatIconModule, CommonModule],
      exports: [...components]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { CxNumberInputComponent, CxNumberInputModule };
