import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Router } from '@angular/router';
import {
  MSAL_GUARD_CONFIG,
  MsalBroadcastService,
  MsalGuardConfiguration,
  MsalService,
} from '@azure/msal-angular';
import {
  EventMessage,
  EventType,
  InteractionStatus,
  RedirectRequest,
} from '@azure/msal-browser';
import { filter, Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'hpm-login-page',
  templateUrl: './login-page.component.html',
  styleUrl: './login-page.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginPageComponent implements OnInit, OnDestroy {
  private onDestroy$: Subject<void> = new Subject();
  isLoggedIn = false;

  constructor(
    private router: Router,
    @Inject(MSAL_GUARD_CONFIG)
    private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
  ) {}

  ngOnInit(): void {
    this.authService.handleRedirectObservable().subscribe();
    this.redirectToLoginOnAccountChange();
    this.reactToUserLoggedIn();
  }

  private reactToUserLoggedIn(): void {
    this.msalBroadcastService.inProgress$
      .pipe(
        filter(
          (status: InteractionStatus) => status === InteractionStatus.None,
        ),
        takeUntil(this.onDestroy$),
      )
      .subscribe(() => {
        this.checkAndSetActiveAccount();
        this.setIsLoggedIn();
        this.redirectToOrderPage();
      });
  }

  private redirectToLoginOnAccountChange(): void {
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter(
          (msg: EventMessage) =>
            msg.eventType === EventType.ACCOUNT_ADDED ||
            msg.eventType === EventType.ACCOUNT_REMOVED,
        ),
      )
      .subscribe((result: EventMessage) => {
        if (this.authService.instance.getAllAccounts().length === 0) {
          this.loginRedirect();
          this.checkAndSetActiveAccount();
          this.setIsLoggedIn();
        }
      });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  redirectToOrderPage(): void {
    if (!this.loggedIn) {
      this.loginRedirect();
    } else {
      this.router.navigateByUrl('/order');
    }
  }

  private checkAndSetActiveAccount(): void {
    const activeAccount = this.authService.instance.getActiveAccount();

    if (
      !activeAccount &&
      this.authService.instance.getAllAccounts().length > 0
    ) {
      const accounts = this.authService.instance.getAllAccounts();
      this.authService.instance.setActiveAccount(accounts[0]);
    }
  }

  private loginRedirect(): void {
    if (this.msalGuardConfig.authRequest) {
      this.authService.loginRedirect({
        ...this.msalGuardConfig.authRequest,
      } as RedirectRequest);
    } else {
      this.authService.loginRedirect();
    }
  }

  public logout(): void {
    this.authService.logout({
      idTokenHint: this.authService.instance.getAllAccounts()[0].idToken,
    });
  }

  private setIsLoggedIn(): void {
    this.isLoggedIn = this.authService.instance.getAllAccounts().length > 0;
  }

  public get loggedIn(): boolean {
    return this.isLoggedIn;
  }
}
