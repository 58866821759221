import {HttpClient} from '@angular/common/http';
import {Directive, HostListener, Input} from '@angular/core';

/**
 * This class is for file downloads. By doing it in Angular, the MSAL interceptor can intercept the call
 * and add the authentication header. Without this, the browser would do the download directly and NOT
 * add the authentication header.
 */
@Directive({
  selector: '[hpmSecureDownload]',
})
export class SecureDownloadDirective {
  @Input() url!: string;
  @Input() fileName!: string;

  constructor(private http: HttpClient) {}

  @HostListener('click')
  downloadFile(): void {
    if (this.url) {
      this.http.get(this.url, { responseType: 'blob' }).subscribe(
        (blob) => {
          const downloadURL = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = downloadURL;
          link.download = this.fileName ? this.fileName : 'download'; // Replace with the desired file name
          link.click();

          window.URL.revokeObjectURL(downloadURL);
        },
        (error) => {
          console.error('Download error:', error);
        },
      );
    }
  }
}
