import * as i0 from '@angular/core';
import { Component, Input, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import * as i1 from '@angular/material/button';
import { MatButtonModule } from '@angular/material/button';
const _c0 = [[["cx-message-image"]], [["cx-message-title"]], [["cx-message-text"]], [["cx-message-button"]]];
const _c1 = ["cx-message-image", "cx-message-title", "cx-message-text", "cx-message-button"];
const _c2 = ["*"];
class CxMessageComponent {
  static {
    this.ɵfac = function CxMessageComponent_Factory(t) {
      return new (t || CxMessageComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: CxMessageComponent,
      selectors: [["cx-message"]],
      ngContentSelectors: _c1,
      decls: 11,
      vars: 0,
      consts: [[1, "container", "cx-message-page-height", "d-flex", "justify-content-center", "flex-column"], [1, "d-flex", "justify-content-center", "align-items-center"], [1, "d-flex", "pt-xl", "justify-content-center", "align-items-center"], [1, "m-0"], [1, "d-flex", "pt-m", "justify-content-center", "align-items-center"], [1, "font-l"], [1, "d-flex", "pt-ml", "justify-content-center", "align-items-center"]],
      template: function CxMessageComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef(_c0);
          i0.ɵɵelementStart(0, "div", 0)(1, "div", 1);
          i0.ɵɵprojection(2);
          i0.ɵɵelementEnd();
          i0.ɵɵelementStart(3, "div", 2)(4, "h2", 3);
          i0.ɵɵprojection(5, 1);
          i0.ɵɵelementEnd()();
          i0.ɵɵelementStart(6, "div", 4)(7, "div", 5);
          i0.ɵɵprojection(8, 2);
          i0.ɵɵelementEnd()();
          i0.ɵɵelementStart(9, "div", 6);
          i0.ɵɵprojection(10, 3);
          i0.ɵɵelementEnd()();
        }
      },
      styles: [".cx-message-page-height[_ngcontent-%COMP%]{height:calc(100vh - 76px)}"]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CxMessageComponent, [{
    type: Component,
    args: [{
      selector: 'cx-message',
      template: "<div class=\"container cx-message-page-height d-flex justify-content-center flex-column\">\n  <div class=\"d-flex justify-content-center align-items-center\">\n    <ng-content select=\"cx-message-image\"></ng-content>\n  </div>\n  <div class=\"d-flex pt-xl justify-content-center align-items-center\">\n    <h2 class=\"m-0\">\n      <ng-content select=\"cx-message-title\"></ng-content>\n    </h2>\n  </div>\n  <div class=\"d-flex pt-m justify-content-center align-items-center\">\n    <div class=\"font-l\">\n      <ng-content select=\"cx-message-text\"></ng-content>\n    </div>\n  </div>\n  <div class=\"d-flex pt-ml justify-content-center align-items-center\">\n    <ng-content select=\"cx-message-button\"></ng-content>\n  </div>\n</div>\n",
      styles: [".cx-message-page-height{height:calc(100vh - 76px)}\n"]
    }]
  }], null, null);
})();
class CxMessageButtonComponent {
  static {
    this.ɵfac = function CxMessageButtonComponent_Factory(t) {
      return new (t || CxMessageButtonComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: CxMessageButtonComponent,
      selectors: [["cx-message-button"]],
      inputs: {
        buttonText: "buttonText"
      },
      decls: 2,
      vars: 1,
      consts: [["mat-flat-button", "", "data-cy", "cx-message-button", "color", "accent"]],
      template: function CxMessageButtonComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelementStart(0, "button", 0);
          i0.ɵɵtext(1);
          i0.ɵɵelementEnd();
        }
        if (rf & 2) {
          i0.ɵɵadvance();
          i0.ɵɵtextInterpolate1(" ", ctx.buttonText, "\n");
        }
      },
      dependencies: [i1.MatButton]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CxMessageButtonComponent, [{
    type: Component,
    args: [{
      selector: 'cx-message-button',
      template: "<button mat-flat-button data-cy=\"cx-message-button\" color=\"accent\">\n  {{ buttonText }}\n</button>\n"
    }]
  }], null, {
    buttonText: [{
      type: Input
    }]
  });
})();
class CxMessageImageComponent {
  static {
    this.ɵfac = function CxMessageImageComponent_Factory(t) {
      return new (t || CxMessageImageComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: CxMessageImageComponent,
      selectors: [["cx-message-image"]],
      inputs: {
        imageSrc: "imageSrc"
      },
      decls: 1,
      vars: 1,
      consts: [["alt", "", 1, "cx-message-image", "w-auto", 3, "src"]],
      template: function CxMessageImageComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelement(0, "img", 0);
        }
        if (rf & 2) {
          i0.ɵɵproperty("src", ctx.imageSrc, i0.ɵɵsanitizeUrl);
        }
      },
      styles: [".cx-message-image[_ngcontent-%COMP%]{max-height:350px}"]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CxMessageImageComponent, [{
    type: Component,
    args: [{
      selector: 'cx-message-image',
      template: "<img alt=\"\" class=\"cx-message-image w-auto\" [src]=\"imageSrc\" />\n",
      styles: [".cx-message-image{max-height:350px}\n"]
    }]
  }], null, {
    imageSrc: [{
      type: Input
    }]
  });
})();
class CxMessageTextComponent {
  static {
    this.ɵfac = function CxMessageTextComponent_Factory(t) {
      return new (t || CxMessageTextComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: CxMessageTextComponent,
      selectors: [["cx-message-text"]],
      ngContentSelectors: _c2,
      decls: 1,
      vars: 0,
      template: function CxMessageTextComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      }
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CxMessageTextComponent, [{
    type: Component,
    args: [{
      selector: 'cx-message-text',
      template: "<ng-content></ng-content>\n"
    }]
  }], null, null);
})();
class CxMessageTitleComponent {
  static {
    this.ɵfac = function CxMessageTitleComponent_Factory(t) {
      return new (t || CxMessageTitleComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: CxMessageTitleComponent,
      selectors: [["cx-message-title"]],
      ngContentSelectors: _c2,
      decls: 1,
      vars: 0,
      template: function CxMessageTitleComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      }
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CxMessageTitleComponent, [{
    type: Component,
    args: [{
      selector: 'cx-message-title',
      template: "<ng-content></ng-content>\n"
    }]
  }], null, null);
})();
const components = [CxMessageComponent, CxMessageTitleComponent, CxMessageTextComponent, CxMessageButtonComponent, CxMessageImageComponent];
class CxMessageModule {
  static {
    this.ɵfac = function CxMessageModule_Factory(t) {
      return new (t || CxMessageModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: CxMessageModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      imports: [CommonModule, MatButtonModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CxMessageModule, [{
    type: NgModule,
    args: [{
      declarations: [...components],
      imports: [CommonModule, MatButtonModule],
      exports: [...components]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { CxMessageButtonComponent, CxMessageComponent, CxMessageImageComponent, CxMessageModule, CxMessageTextComponent, CxMessageTitleComponent };
