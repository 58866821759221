import * as i1 from '@angular/common/http';
import { HttpEventType } from '@angular/common/http';
import * as i0 from '@angular/core';
import { Injectable, Pipe, EventEmitter, Component, ChangeDetectionStrategy, Input, Output, NgModule } from '@angular/core';
import { Subject, of, forkJoin } from 'rxjs';
import { catchError, takeUntil } from 'rxjs/operators';
import * as i2 from '@angular/common';
import { CommonModule } from '@angular/common';
import * as i3 from '@angular/material/button';
import { MatButtonModule } from '@angular/material/button';
import * as i4 from '@angular/material/icon';
import { MatIconModule } from '@angular/material/icon';
import * as i5 from '@angular/material/progress-bar';
import { MatProgressBarModule } from '@angular/material/progress-bar';
function CxFileUploaderComponent_li_10_mat_progress_bar_13_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "mat-progress-bar", 17);
  }
  if (rf & 2) {
    const fileProgress_r2 = i0.ɵɵnextContext().$implicit;
    i0.ɵɵproperty("value", fileProgress_r2.progress);
  }
}
function CxFileUploaderComponent_li_10_span_14_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "span", 18);
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r5 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate1(" ", ctx_r5.errorMessage, " ");
  }
}
const _c0 = a0 => ({
  error: a0
});
function CxFileUploaderComponent_li_10_Template(rf, ctx) {
  if (rf & 1) {
    const _r8 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "li", 8)(1, "div", 9)(2, "mat-icon", 10);
    i0.ɵɵtext(3, "receipt");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(4, "div", 11)(5, "span", 12);
    i0.ɵɵtext(6);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(7, "span", 13);
    i0.ɵɵtext(8);
    i0.ɵɵpipe(9, "fileSize");
    i0.ɵɵelementEnd()();
    i0.ɵɵelementStart(10, "button", 14);
    i0.ɵɵlistener("click", function CxFileUploaderComponent_li_10_Template_button_click_10_listener() {
      const restoredCtx = i0.ɵɵrestoreView(_r8);
      const fileProgress_r2 = restoredCtx.$implicit;
      const ctx_r7 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r7.onRemoveFile(fileProgress_r2.file));
    });
    i0.ɵɵelementStart(11, "mat-icon");
    i0.ɵɵtext(12, "highlight_remove");
    i0.ɵɵelementEnd()()();
    i0.ɵɵtemplate(13, CxFileUploaderComponent_li_10_mat_progress_bar_13_Template, 1, 1, "mat-progress-bar", 15)(14, CxFileUploaderComponent_li_10_span_14_Template, 2, 1, "span", 16);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const fileProgress_r2 = ctx.$implicit;
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction1(7, _c0, fileProgress_r2.state === ctx_r1.cxFileUploadState.Error));
    i0.ɵɵadvance(5);
    i0.ɵɵtextInterpolate(fileProgress_r2.file.name);
    i0.ɵɵadvance(2);
    i0.ɵɵtextInterpolate(i0.ɵɵpipeBind1(9, 5, fileProgress_r2.file.size));
    i0.ɵɵadvance(5);
    i0.ɵɵproperty("ngIf", fileProgress_r2.state === ctx_r1.cxFileUploadState.InProgress);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", fileProgress_r2.state == ctx_r1.cxFileUploadState.Error);
  }
}
const _c1 = a0 => ({
  disabled: a0
});
var CxFileUploadState;
(function (CxFileUploadState) {
  CxFileUploadState[CxFileUploadState["InProgress"] = 0] = "InProgress";
  CxFileUploadState[CxFileUploadState["Completed"] = 1] = "Completed";
  CxFileUploadState[CxFileUploadState["Error"] = 2] = "Error";
})(CxFileUploadState || (CxFileUploadState = {}));
class CxFileUploaderService {
  constructor(http) {
    this.http = http;
  }
  uploadFile(file, endpoint) {
    const formData = new FormData();
    formData.append('file', file, file.name);
    return this.http.post(endpoint, formData, {
      reportProgress: true,
      observe: 'events'
    });
  }
  static {
    this.ɵfac = function CxFileUploaderService_Factory(t) {
      return new (t || CxFileUploaderService)(i0.ɵɵinject(i1.HttpClient));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: CxFileUploaderService,
      factory: CxFileUploaderService.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CxFileUploaderService, [{
    type: Injectable
  }], () => [{
    type: i1.HttpClient
  }], null);
})();
const FILE_SIZES = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
class CxFileSizePipe {
  transform(bytes) {
    if (bytes === 0) {
      return '0 Byte';
    }
    const i = Math.floor(Math.log(bytes) / Math.log(1024));
    return Math.round(bytes / Math.pow(1024, i)) + ' ' + FILE_SIZES[i];
  }
  static {
    this.ɵfac = function CxFileSizePipe_Factory(t) {
      return new (t || CxFileSizePipe)();
    };
  }
  static {
    this.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
      name: "fileSize",
      type: CxFileSizePipe,
      pure: true
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CxFileSizePipe, [{
    type: Pipe,
    args: [{
      name: 'fileSize'
    }]
  }], null, null);
})();
const MAX_PROGRESS = 100;
class CxFileUploaderComponent {
  constructor(cdr, fileUploaderService) {
    this.cdr = cdr;
    this.fileUploaderService = fileUploaderService;
    /**
     * Allow for multiple files to be uploaded
     */
    this.multiple = false;
    /**
     * Allow files to be uploaded manually
     */
    this.manualUpload = false;
    /**
     * Files that are currently being uploaded (Works only with manualUpload = true)
     */
    this.files = [];
    /**
     * Triggers when file is selected
     */
    this.selectFile = new EventEmitter();
    /**
     * Triggers when file is removed
     */
    this.removeFile = new EventEmitter();
    this.cxFileUploadState = CxFileUploadState;
    this.fileProgresses = [];
    this.$destroy = new Subject();
  }
  get disableUpload() {
    return !this.multiple && this.fileProgresses.length === 1;
  }
  get filesInProgress() {
    return this.manualUpload ? this.files : this.fileProgresses;
  }
  ngOnDestroy() {
    this.$destroy.next();
    this.$destroy.complete();
  }
  onFileSelected(event) {
    const files = event.target?.files || [];
    if (!this.multiple) {
      this.fileProgresses = [];
    }
    if (this.manualUpload) {
      this.selectFile.emit(Array.from(files));
    } else {
      for (const file of Array.from(files)) {
        this.uploadFile(file);
      }
    }
  }
  onRemoveFile(file) {
    if (!this.manualUpload) {
      const fileIndex = this.getFileInProgressIndex(file);
      this.fileProgresses[fileIndex]?.$destroy.next();
      this.fileProgresses[fileIndex]?.$destroy.complete();
      this.fileProgresses.splice(fileIndex, 1);
    }
    this.removeFile.emit(file);
  }
  addNewFileProgress(file) {
    this.fileProgresses.push({
      file,
      progress: 0,
      state: CxFileUploadState.InProgress,
      $destroy: new Subject()
    });
  }
  uploadFile(file) {
    this.addNewFileProgress(file);
    this.fileUploaderService.uploadFile(file, this.endpoint).pipe(catchError(() => {
      this.setState(file, CxFileUploadState.Error);
      return of(null);
    }), takeUntil(forkJoin([this.$destroy, this.fileProgresses[this.getFileInProgressIndex(file)].$destroy]))).subscribe(event => {
      if (event) {
        this.updateProgress(event, file);
      }
    });
  }
  updateProgress(event, file) {
    switch (event.type) {
      case HttpEventType.UploadProgress:
        if (event.total) {
          const progress = Math.round(MAX_PROGRESS * event.loaded / event.total);
          this.updateFileProgress(file, progress);
        }
        break;
      case HttpEventType.Response:
        this.setState(file, CxFileUploadState.Completed);
        this.updateFileProgress(file, MAX_PROGRESS);
        break;
    }
  }
  updateFileProgress(file, progress) {
    const index = this.getFileInProgressIndex(file);
    if (index > -1) {
      this.fileProgresses[index].progress = progress;
      this.cdr.detectChanges();
    }
  }
  setState(file, state) {
    const index = this.getFileInProgressIndex(file);
    if (index > -1) {
      this.fileProgresses[index].state = state;
      this.cdr.detectChanges();
    }
  }
  getFileInProgressIndex(file) {
    return this.fileProgresses.findIndex(fp => fp.file === file);
  }
  static {
    this.ɵfac = function CxFileUploaderComponent_Factory(t) {
      return new (t || CxFileUploaderComponent)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(CxFileUploaderService));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: CxFileUploaderComponent,
      selectors: [["cx-file-uploader"]],
      inputs: {
        heading: "heading",
        buttonText: "buttonText",
        description: "description",
        errorMessage: "errorMessage",
        acceptedFileTypes: "acceptedFileTypes",
        endpoint: "endpoint",
        multiple: "multiple",
        manualUpload: "manualUpload",
        files: "files",
        disabled: "disabled"
      },
      outputs: {
        selectFile: "selectFile",
        removeFile: "removeFile"
      },
      decls: 11,
      vars: 10,
      consts: [[1, "cx-file-uploader", 3, "ngClass"], [1, "cx-file-uploader-title"], [1, "cx-file-uploader-description"], ["mat-flat-button", "", "color", "accent", 3, "disabled", "click"], ["type", "file", 1, "d-none", 3, "change"], ["fileInput", ""], [1, "cx-file-list"], ["class", "cx-file-list-item", 4, "ngFor", "ngForOf"], [1, "cx-file-list-item"], [1, "cx-file-list-content", 3, "ngClass"], [1, "mr-s", "flex-shrink-0"], [1, "cx-file-list-details", "d-flex", "flex-column", "flex-grow-1"], [1, "cx-file-list-name"], [1, "font-m", "text-gray-70"], ["mat-icon-button", "", 1, "small", 3, "click"], ["color", "primary", "mode", "determinate", 3, "value", 4, "ngIf"], ["class", "cx-file-list-error", 4, "ngIf"], ["color", "primary", "mode", "determinate", 3, "value"], [1, "cx-file-list-error"]],
      template: function CxFileUploaderComponent_Template(rf, ctx) {
        if (rf & 1) {
          const _r9 = i0.ɵɵgetCurrentView();
          i0.ɵɵelementStart(0, "div", 0)(1, "h3", 1);
          i0.ɵɵtext(2);
          i0.ɵɵelementEnd();
          i0.ɵɵelementStart(3, "p", 2);
          i0.ɵɵtext(4);
          i0.ɵɵelementEnd();
          i0.ɵɵelementStart(5, "button", 3);
          i0.ɵɵlistener("click", function CxFileUploaderComponent_Template_button_click_5_listener() {
            i0.ɵɵrestoreView(_r9);
            const _r0 = i0.ɵɵreference(8);
            return i0.ɵɵresetView(_r0.click());
          });
          i0.ɵɵtext(6);
          i0.ɵɵelementEnd();
          i0.ɵɵelementStart(7, "input", 4, 5);
          i0.ɵɵlistener("change", function CxFileUploaderComponent_Template_input_change_7_listener($event) {
            return ctx.onFileSelected($event);
          });
          i0.ɵɵelementEnd();
          i0.ɵɵelementStart(9, "ul", 6);
          i0.ɵɵtemplate(10, CxFileUploaderComponent_li_10_Template, 15, 9, "li", 7);
          i0.ɵɵelementEnd()();
        }
        if (rf & 2) {
          i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction1(8, _c1, ctx.disabled));
          i0.ɵɵadvance(2);
          i0.ɵɵtextInterpolate(ctx.heading);
          i0.ɵɵadvance(2);
          i0.ɵɵtextInterpolate(ctx.description);
          i0.ɵɵadvance();
          i0.ɵɵproperty("disabled", ctx.disabled || ctx.disableUpload);
          i0.ɵɵadvance();
          i0.ɵɵtextInterpolate1(" ", ctx.buttonText, " ");
          i0.ɵɵadvance();
          i0.ɵɵattribute("multiple", ctx.multiple ? "" : null)("accept", ctx.acceptedFileTypes);
          i0.ɵɵadvance(3);
          i0.ɵɵproperty("ngForOf", ctx.filesInProgress);
        }
      },
      dependencies: [i2.NgClass, i2.NgForOf, i2.NgIf, i3.MatButton, i3.MatIconButton, i4.MatIcon, i5.MatProgressBar, CxFileSizePipe],
      styles: [".cx-file-uploader[_ngcontent-%COMP%]{border:1px solid #b0b0b0;border-radius:8px;padding:24px;background-color:#fff}.cx-file-uploader.disabled[_ngcontent-%COMP%]   .cx-file-uploader-title[_ngcontent-%COMP%], .cx-file-uploader.disabled[_ngcontent-%COMP%]   .cx-file-uploader-description[_ngcontent-%COMP%]{color:#b0b0b0}.cx-file-uploader-title[_ngcontent-%COMP%]{color:#202020;font-size:16px;margin:0}.cx-file-uploader-description[_ngcontent-%COMP%]{margin-top:12px;margin-bottom:12px}.cx-file-list[_ngcontent-%COMP%]{margin:0;padding:0}.cx-file-list-item[_ngcontent-%COMP%]{padding:0;margin-top:16px;list-style:none}.cx-file-list-error[_ngcontent-%COMP%]{color:#eb0000;font-size:12px}.cx-file-list-content[_ngcontent-%COMP%]{display:flex;align-items:center;padding-bottom:16px;border-bottom:1px solid #b0b0b0}.cx-file-list-content.error[_ngcontent-%COMP%]{border-bottom:1px solid #eb0000}.cx-file-list-details[_ngcontent-%COMP%]{overflow:hidden}.cx-file-list-name[_ngcontent-%COMP%]{white-space:nowrap;overflow:hidden;text-overflow:ellipsis}"],
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CxFileUploaderComponent, [{
    type: Component,
    args: [{
      selector: 'cx-file-uploader',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: "<div class=\"cx-file-uploader\" [ngClass]=\"{disabled}\">\n  <h3 class=\"cx-file-uploader-title\">{{heading}}</h3>\n  <p class=\"cx-file-uploader-description\">{{description}}</p>\n  <button\n    mat-flat-button color=\"accent\"\n    (click)=\"fileInput.click()\"\n    [disabled]=\"disabled || disableUpload\">\n    {{buttonText}}\n  </button>\n  <input\n    type=\"file\"\n    class=\"d-none\"\n    #fileInput\n    [attr.multiple]=\"multiple ? '' : null\"\n    [attr.accept]=\"acceptedFileTypes\"\n    (change)=\"onFileSelected($event)\"/>\n\n  <ul class=\"cx-file-list\">\n    <li\n      class=\"cx-file-list-item\"\n      *ngFor=\"let fileProgress of filesInProgress; let i = index\">\n\n      <div class=\"cx-file-list-content\" [ngClass]=\"{error: fileProgress.state === cxFileUploadState.Error}\">\n        <mat-icon class=\"mr-s flex-shrink-0\">receipt</mat-icon>\n        <div class=\"cx-file-list-details d-flex flex-column flex-grow-1\">\n          <span class=\"cx-file-list-name\">{{fileProgress.file.name}}</span>\n          <span class=\"font-m text-gray-70\">{{fileProgress.file.size | fileSize}}</span>\n        </div>\n        <button\n          (click)=\"onRemoveFile(fileProgress.file)\"\n          mat-icon-button class=\"small\">\n          <mat-icon>highlight_remove</mat-icon>\n        </button>\n      </div>\n\n      <mat-progress-bar\n        *ngIf=\"fileProgress.state === cxFileUploadState.InProgress\"\n        color=\"primary\" mode=\"determinate\"\n        [value]=\"fileProgress.progress\">\n      </mat-progress-bar>\n\n      <span\n        class=\"cx-file-list-error\"\n        *ngIf=\"fileProgress.state == cxFileUploadState.Error\">\n        {{errorMessage}}\n      </span>\n    </li>\n  </ul>\n</div>\n",
      styles: [".cx-file-uploader{border:1px solid #b0b0b0;border-radius:8px;padding:24px;background-color:#fff}.cx-file-uploader.disabled .cx-file-uploader-title,.cx-file-uploader.disabled .cx-file-uploader-description{color:#b0b0b0}.cx-file-uploader-title{color:#202020;font-size:16px;margin:0}.cx-file-uploader-description{margin-top:12px;margin-bottom:12px}.cx-file-list{margin:0;padding:0}.cx-file-list-item{padding:0;margin-top:16px;list-style:none}.cx-file-list-error{color:#eb0000;font-size:12px}.cx-file-list-content{display:flex;align-items:center;padding-bottom:16px;border-bottom:1px solid #b0b0b0}.cx-file-list-content.error{border-bottom:1px solid #eb0000}.cx-file-list-details{overflow:hidden}.cx-file-list-name{white-space:nowrap;overflow:hidden;text-overflow:ellipsis}\n"]
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: CxFileUploaderService
  }], {
    heading: [{
      type: Input,
      args: [{
        required: true
      }]
    }],
    buttonText: [{
      type: Input,
      args: [{
        required: true
      }]
    }],
    description: [{
      type: Input
    }],
    errorMessage: [{
      type: Input,
      args: [{
        required: true
      }]
    }],
    acceptedFileTypes: [{
      type: Input
    }],
    endpoint: [{
      type: Input
    }],
    multiple: [{
      type: Input
    }],
    manualUpload: [{
      type: Input
    }],
    files: [{
      type: Input
    }],
    disabled: [{
      type: Input
    }],
    selectFile: [{
      type: Output
    }],
    removeFile: [{
      type: Output
    }]
  });
})();
class CxFileUploaderModule {
  static {
    this.ɵfac = function CxFileUploaderModule_Factory(t) {
      return new (t || CxFileUploaderModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: CxFileUploaderModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      providers: [CxFileUploaderService],
      imports: [CommonModule, MatButtonModule, MatIconModule, MatProgressBarModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CxFileUploaderModule, [{
    type: NgModule,
    args: [{
      declarations: [CxFileUploaderComponent, CxFileSizePipe],
      imports: [CommonModule, MatButtonModule, MatIconModule, MatProgressBarModule],
      providers: [CxFileUploaderService],
      exports: [CxFileUploaderComponent]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { CxFileUploaderComponent, CxFileUploaderModule };
