import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
} from '@angular/core';
import { Router } from '@angular/router';
import { MatTabChangeEvent } from '@angular/material/tabs';
import {
  AuthorizedUser,
  hasAnyOfThoseRoles,
  UserRole,
} from '../../model/user.model';
import { UserHttpService } from '../../service/user-http.service';
import { skipWhile } from 'rxjs';

@Component({
  selector: 'hpm-tab-navigation',
  templateUrl: './tab-navigation.component.html',
  styleUrl: './tab-navigation.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TabNavigationComponent implements OnInit {
  selectedIndex = 0;
  tabIndexRouteMap: string[] = ['/order', '/template', '/admin'];
  currentUser: AuthorizedUser | null = null;

  protected readonly userRole = UserRole;

  constructor(
    private router: Router,
    private userService: UserHttpService,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.getCurrentUser();
  }

  getCurrentUser(): void {
    this.userService
      .getCurrentUser()
      .pipe(skipWhile((user) => user === null))
      .subscribe((user) => {
        this.currentUser = user;
        this.setTabIndexByRoute();
        this.cdr.detectChanges();
      });
  }

  setTabIndexByRoute(): void {
    const currentRoute = this.router.url.toString();
    this.selectedIndex = this.tabIndexRouteMap.findIndex((route) =>
      currentRoute.match(route),
    );
  }

  navigateOnTab(event: MatTabChangeEvent): void {
    const url = this.tabIndexRouteMap[event.index] || '/';
    this.router.navigateByUrl(url);
  }

  protected readonly hasAnyOfThoseRoles = hasAnyOfThoseRoles;
}
