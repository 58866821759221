<div class="login-wrapper">
  <img src="/assets/images/no_image.png" alt="Logo"/>
  <H2>Hygieneplan Manager 3.0</H2>
  <p class="login-text">{{ 'LOGIN_PAGE.LOGIN_DIRECTIONS' | translate }}</p>
  <button
    (click)="redirectToOrderPage()" color="accent" class="cx-menu-button" mat-flat-button>
    <p>{{'LOGIN_PAGE.LOGIN_TEXT' | translate}}</p>
  </button>
  <p>
    <cx-logo [width]='160'></cx-logo>
  </p>
</div>
